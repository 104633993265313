import React from 'react';
import {  Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import Header from "./components/header/index.jsx";
import MobileHeader from './components/mobile-header/index.jsx';
import Home from './components/home/index';
// import OurGoals from './components/our-goals/index.jsx';
import NewsDetails from './components/news-details/index.jsx';
import NewsArticles from './components/news-articles/index.jsx';
import Services from './components/services/index.jsx';
import ServicesDetails from './components/services-details/index.jsx';
import About from './components/about-us/index.jsx';
import Contact from './components/contact.jsx';
import Partners from './components/partners.jsx';
import Gallery from './components/gallery.jsx';
import ServiceQuery from './components/query.jsx';
import Footer from "./components/footer/index.jsx";
const ClientAppUniversal = function (props) {
    return (
        <>
            <div className="industify_fn_wrapper_all" >

                {/* <!-- Wrapper --> */}
                <div className="industify_fn_wrapper">

                    <Router>
						<div>
							<Route render={(props)=> <Header {...props}/>} />
                            <Route render={(props) => <MobileHeader {...props}/>} />
							<Switch>
								<Route path="/" exact component={Home} />
                                {/*<Route path="/rcrisp/our-goals" exact component={OurGoals} />*/}
                                <Route path="/contact" exact component={Contact} />
                                <Route path="/gallery" exact component={Gallery} />
                                <Route path="/our-partners" exact component={Partners} />
                                <Route path="/service-query" exact component={ServiceQuery} />
                                <Route path="/about-us" exact component={About} />
                                <Route path="/news-articles" exact component={NewsArticles} />
                                <Route path="/our-services" exact component={Services} />
                                <Route path="/news-articles/:id" exact component={NewsDetails} />
                                <Route path="/services/:id" exact component={ServicesDetails} />
							</Switch>
							<Route render={(props)=> <Footer {...props}/>} />
						</div>
					</Router>

                </div>
            </div>
        </>
    )
}
export default ClientAppUniversal;

