import React, { Component } from "react";
import { Link } from "react-router-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import axios from 'axios';
import qs from 'qs';
import {APIURL} from '../../../matcher';
class Images extends Component {
    constructor(props) 
    {
        super(props)
        this.state = {
          currentImage : 0,
          setCurrentImage : 0,
          viewerIsOpen : false,
          setViewerIsOpen : false,
          photos : []
        };
    }

    componentDidMount() 
    {  
        axios.get(APIURL+'partners')
        .then((res) => 
        {
            if(res.data.list.length > 0)
            {
              this.setState({
                photos: res.data.list,
                loading: false,
              });
            }
            else
            {
              this.setState({
                photos: [],
                loading: false,
              });
            }
        })
    }

    render() 
    {
        return (
            <div>
             <br/>
              <Gallery photos={this.state.photos}  />
             <br/>
            </div>
        );
    }
}
export default Images;
