import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Facebook, Instagram, Linkedin, Twitter } from '../../assets/svg/social/IconSocial';
import logo from "../../assets/img/logo-light.png";
import logo1 from "../../assets/img/logo-dark.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faHome} from '@fortawesome/free-solid-svg-icons';
import Img from "../../assets/img/call.png"
class Header extends Component {
    constructor(props) 
    {
        super(props);
    }

    render() 
    {
        return (
        <>
            <div className="industify_fn_header">
                {/* <!-- Header: Top Panel --> */}
                <div className="industify_fn_toppanel">
                    <div className="left_panel">
                        <div className="info">
                            <Link href="tel:+919013976908"><a><FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;+91-9013976908</a></Link>
                        </div>
                        <div className="info">
                            <Link href="mailto:info@rcrispsolutions.com"><a><FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;info@rcrispsolutions.com</a></Link>
                        </div>
                        <div className="industify_fn_social_list">
                            <ul>
                            <li>
                                    <Link href="#">
                                        <a target="_blank" rel="noreferrer">
                                            <Facebook className="fn__svg" />
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="#"><a target="_blank" rel="noreferrer">
                                        <Twitter className="fn__svg" />
                                    </a></Link>
                                </li>
                                <li>
                                    <Link href="#"><a target="_blank" rel="noreferrer">
                                        <Instagram className="fn__svg" />
                                    </a></Link>
                                </li>
                                <li>
                                    <Link href="#"><a target="_blank" rel="noreferrer">
                                        <Linkedin className="fn__svg" />
                                    </a></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <!-- /Header: Top Panel --> */}

                {/* <!-- Header: Bottom Panel --> */}
                <div className="header_inner">
                    <div className="menu_logo">
                        <Link to="/">
                            <a>
                                <img className="desktop_logo" src={logo} alt="Rcrisp Solutions" />
                                <img className="desktop_logo_dark" src={logo1} alt="Rcrisp Solutions" />
                            </a>
                        </Link>
                    </div>
                    <div className="menu_nav">
                        <ul className="industify_fn_main_nav vert_nav">
                            <li>
                                <Link to="/"><a>Home</a></Link>
                            </li>
                            <li>
                                <Link to="/about-us"><a>About Us</a></Link>
                            </li>
                            <li>
                                <Link to="/our-services"><a>Our Services</a></Link>
                            </li>
                            <li>
                                <Link to="/news-articles"><a>News/Article</a></Link>
                            </li>
                            <li>
                                <Link to="/gallery"><a>Gallery</a></Link>
                            </li>
                            <li>
                                <Link to="/contact"><a>Contact Us</a></Link>
                            </li>
                        </ul>
                    </div>
                    <div className="toll_free_lang">
                        <div className="toll_free">
                            <span className="shape1"></span>
                            <span className="shape2"></span>
                            <span className="shape3"></span>
                            <div className="tf_in">
                                <div className="img_holder" style={{ backgroundImage: `url(${Img})` }}></div>
                                <p><span>Toll Free:</span> +91-9013976908</p>
                            </div>
                        </div>

                    </div>
                </div>
                {/* <!-- /Header: Bottom Panel --> */}

            </div>
            <div className="my-class"></div>
        </>
    )
    }
}
export default Header;
