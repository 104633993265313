import React, { Component } from "react";
import { Link } from "react-router-dom";
import Partners from '../Partners/index';
class OurGoals extends Component {
    constructor(props) 
    {
        super(props);
    }

    render() 
    {
       return (
        <>
        {/* Page Title  */}
                <div className="industify_fn_pagetitle">
                    <div className="container">
                        <div className="title_holder">
                            <h3>About Us</h3>
                            <div className="industify_fn_breadcrumbs">
                                <ul>
                                    <li><Link href="/"><a title="Home">Home</a></Link></li>
                                    <li className="separator"><span></span></li>
                                    <li><span className="bread-current">About Us</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /Page Title  */}

                {/* Principles  */}
                <div className="industify_fn_principles">
                    <div className="container">
                       <p className="aboutUs">RCrisp is synonymous with providing solution to esteemed Customer base. We deliver all solutions under one umbrella with commitment and dedication. Company's vision is to provide environment friendly engineering solutions with global footprint, delivering sustainable value for money to all our esteemed customers.</p>
                       <h4 className="aboutHead">Quality Policy </h4>
                       <p className="aboutcontent">RCrisp Solutions is committed to apply Total Quality Management systems within its business to meet the requirements of the Customer base :</p>
                       <ul className="aboutList">
                           <li>Periodic reviews of the performance of the processes within respective areas. </li>
                           <li>Document control, process control, quality checkpoints, inspection, constant monitoring of work quality with work safety and effective operation and maintenance management.</li>
                           <li>Continually improve the effectiveness of the quality management systems through management review.</li>
                       </ul>
                       <h4 className="aboutHead">Safety First </h4>
                       <p className="aboutcontent">The company lives by its standards: Awareness promotes Safety, prevents accidents and improves performance. Our objectives are set out as below:</p>
                       <ul className="aboutList">
                            <li>Zero accidents. </li>
                            <li>Zero environmental incident.</li>
                            <li>All activities to be carried out in accordance with the standard safety working practice and procedures.</li>
                            <li>All employees and contractor’s visitors assigned to the project are responsible for their own safety and for those around them.</li>
                            <li>In the event of any accident, prompt action to be taken to address the situation.</li>
                            <li>Regular safety toolbox meeting held with the workforce.</li>
                            <li>Proactive approach to safety management is adhered to at all times.</li>
                            <li>Maintaining clean and tidy site is of the utmost importance (5S).</li>
                       </ul>
                        <div className="principles">
                            <ul>
                                <li>
                                    <div className="item">
                                        <div className="item_left">
                                            <h2>01.</h2>
                                            <h3>Mission</h3>
                                        </div>
                                        <div className="item_right">
                                            <p>Our mission is to cater the industry with best quality powder coatings wherever the corrosion protection is the basic need. Our technical team to understand the requirements and get the desired results.</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="item">
                                        <div className="item_left">
                                            <h2>02.</h2>
                                            <h3>Vision</h3>
                                        </div>
                                        <div className="item_right">
                                            <p>Our Vision is to see ourselves as the most trusted and reliable supplier of high quality powder coatings in both the Indian and International Market. To achieve Market Leadership position in our business.</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="item">
                                        <div className="item_left">
                                            <h2>03.</h2>
                                            <h3>Value</h3>
                                        </div>
                                        <div className="item_right">
                                            <p>Consistent to high quality and keep researching for further improvements.
Striving for long term relationship with our end users, vendors and business associates.</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="item">
                                        <div className="item_left">
                                            <h2>04.</h2>
                                            <h3>Quality Assurance</h3>
                                        </div>
                                        <div className="item_right">
                                            <p>Being a client-centric organization, we are engaged in bringing forth the clients an excellent assortment of Powder Coating. All these products are manufactured as per the international quality standards using genuine components. Besides, we employ high end tools and sophisticated technology in the fabrication process. Additionally, we have employed quality inspectors, who test the entire range of products right from the procurement of raw material till the final dispatch, on various parameters, to ensure their quality, flawlessness and zero-defect.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* /Principles  */}
               <Partners />
               <br />
        </>
    )
    }
}
export default OurGoals;
