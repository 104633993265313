import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Location } from '../../assets/svg/icon';
import Sidebar from '../sidebar';
import Img from "../../assets/img/blog/1.jpg"
import axios from 'axios';
import qs from 'qs';
import {APIURL} from '../../../matcher';
class ServicesDetails extends Component {
    constructor(props) 
    {
        super(props);
        this.state = {
          details: ''
        };
    }

    componentDidMount() 
    {  
        const url = this.props.location.pathname;
        const segment = url.substring(url.lastIndexOf('/') + 1);

        axios.get(APIURL+'service-details/'+segment)
        .then((res) => 
        {
              this.setState({
                details: res.data.details,
                loading: false,
              });
        })
    }

    render() 
    {
        const { details } = this.state;

            return (
                <>
                {/* Page Title */}
                <div className="industify_fn_pagetitle">
                    <div className="container">
                        <div className="title_holder">
                            <h3>{details.name}</h3>
                            <div className="industify_fn_breadcrumbs">
                                <ul>
                                    <li><Link href="/"><a title="Home">Home</a></Link></li>
                                    <li className="separator"><span></span></li>
                                    <li><Link href="/our-services"><a title="Our Services">Our Services</a></Link></li>
                                    <li className="separator"><span></span></li>
                                    <li><span className="bread-current">{details.name}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /Page Title */}

                {/* Sidebar Page */}
                <div className="industify_fn_sidebarpage">
                    <div className="container">
                        <div className="s_inner">
                            {/* Main Sidebar: Left */}
                            <div className="industify_fn_leftsidebar">
                                {/* Single Blog */}
                                <div className="industify_fn_blog_single">
                                    <div className="img_holder">
                                        <img src={details.image} alt="" />
                                    </div>
                                    <div className="desc_holder">
                                        <p dangerouslySetInnerHTML={{__html:details.description}}></p>
                                    </div>
                                </div>

                                {/* Call to Action Shortcode (with corner)  */}
                                    <div className="fn_cs_call_to_action corner">
                                        <div className="container">
                                            <div className="cta_holder">
                                                <div className="title_holder">
                                                    <h3>Industify LLC</h3>
                                                    <p>We build your dream house. Contact us for detailed information.</p>
                                                </div>
                                                <div className="link_holder">
                                                    <a href="/service-query">Request For Quote</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /Call to Action Shortcode (with corner)  */}
                                    
                                {/* /Single Blog */}
                            </div>
                            {/* /Main Sidebar: Left */}
                            {/* Main Sidebar: Right */}
                            <div className="industify_fn_rightsidebar">
                                {/* Get Sidebar */}
                                <Sidebar/>
                                {/* /Get Sidebar */}
                            </div>
                            {/* Main Sidebar: Right */}

                        </div>
                    </div>
                </div>
                {/* /Sidebar Page */}
                {/*<div className="contact_in">
                   
                </div>*/}
                </>
            )     
    }
}
export default ServicesDetails;
