import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Facebook, Instagram, Linkedin, Twitter, Vk } from '../../assets/svg/social/IconSocial';
import logo from "../../assets/img/logo-light.png";
import logo1 from "../../assets/img/logo-dark.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faHome } from '@fortawesome/free-solid-svg-icons';
import Img from "../../assets/img/call.png"
class MobileHeader extends Component {
    constructor(props) 
    {
        super(props);
        this.state = {
          isMobileMenu : false,
          setMobileMenu: ''
        };
    }


    render() 
    {
        return (
        <>
            <div className="industify_fn_mobilemenu_wrap">
                <div className="industify_fn_toppanel">
                    <div className="left_panel">
                            <div className="info"><Link href="tel:+919013976908"><a><FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;+91-9013976908</a></Link></div>
                            <div className="info"><Link href="mailto:info@rcrispsolutions.com"><a><FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;info@rcrispsolutions.com</a></Link></div>
                        <div className="industify_fn_social_list">
                            <ul>
                                <li>
                                    <Link href="#"><a target="_blank" rel="noreferrer">
                                        <Facebook className="fn__svg" />
                                    </a></Link>
                                </li>
                                <li>
                                    <Link href="#"><a target="_blank" rel="noreferrer">
                                        <Twitter className="fn__svg" />
                                    </a></Link>
                                </li>
                                <li>
                                    <Link href="#"><a target="_blank" rel="noreferrer">
                                        <Instagram className="fn__svg" />
                                    </a></Link>
                                </li>
                                <li>
                                    <Link href="#"><a target="_blank" rel="noreferrer">
                                        <Vk className="fn__svg" />
                                    </a></Link>
                                </li>
                                <li>
                                    <Link href="#"><a target="_blank" rel="noreferrer">
                                        <Linkedin className="fn__svg" />
                                    </a></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <!-- TOLL FREE MOBILE --> */}
                {/* <!-- /TOLL FREE MOBILE --> */}
                {/* <!-- LOGO & HAMBURGER --> */}
                <div className="logo_hamb">
                    <div className="in">
                        <div className="menu_logo">
                                <Link href="/"><a><img src={logo} alt="Industry Template" /></a></Link>
                        </div>
                        <div >
                            <div className="hamburger-box">
                                <div className="hamburger-inner"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /LOGO & HAMBURGER --> */}
                {/* <!-- MOBILE DROPDOWN MENU --> */}
                <div className={!this.state.isMobileMenu ? "mobilemenu" : "mobilemenu opened d-block"}>
                    <div>
                        <nav >
                            <ul className="metismenu" id="metismenu">
                                <li>
                                    <Link to=""><a>Home</a></Link>
                                </li>
                                <li>
                                    <Link to="/about-us"><a>About Us</a></Link>
                                </li>
                                <li>
                                    <Link to="/our-services"><a>Our Services</a></Link>
                                </li>
                                <li>
                                    <Link to="/news-articles"><a>News/Article</a></Link>
                                </li>
                                <li>
                                    <Link to="/our-partners"><a>Our Partners</a></Link>
                                </li>
                                <li>
                                    <Link to="/gallery"><a>Gallery</a></Link>
                                </li>
                                <li>
                                    <Link to="/contact"><a>Contact Us</a></Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                {/* <!-- /MOBILE DROPDOWN MENU --> */}
            </div>
        </>
    )
    }
}
export default MobileHeader;
