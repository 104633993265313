import React, { Component } from "react";
import { Link } from "react-router-dom";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import SwiperCore, { Navigation } from 'swiper';
import { Arrow_r } from '../../assets/svg/icon';
import axios from 'axios';
import qs from 'qs';
import { APIURL } from '../../../matcher';
// Import images
SwiperCore.use([Navigation]);
class Partners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lists: ''
        };
    }

    componentDidMount() {
        axios.get(APIURL + 'partners')
            .then((res) => {
                if (res.data.list.length > 0) {
                    this.setState({
                        lists: res.data.list,
                        loading: false,
                    });
                }
                else {
                    this.setState({
                        lists: '',
                        loading: false,
                    });
                }
            })
    }

    render() {
        const { lists } = this.state;
        if (lists.length != 0) {
            return (
                <>
                    {/* <!-- Service Query Shortcode --> */}
                    <div className="fn_cs_service_query" data-mobile="disable" data-column-count="4">
                        <div className="service_part">
                            <div className="owl-carousel">
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={2}
                                    autoplaydisableoninteraction={false}
                                    loop={true}
                                    // pagination={{ clickable: true }}
                                    className="custom-class"
                                    navigation={{
                                        prevEl: ".fn_prev",
                                        nextEl: ".fn_next",
                                    }}
                                    breakpoints={{

                                        // when window width is >= 768px

                                        768: {
                                            // when window width is >= 992px
                                            slidesPerView: 3,
                                        },
                                        1200: {
                                            // when window width is >= 992px
                                            slidesPerView: 4,
                                        }
                                    }}
                                >
                                    {lists.map((item, i) => {
                                        console.log(item.name)
                                        return (
                                            <SwiperSlide key={i}>
                                                <div className="item">

                                                    <div className="img_holder">
                                                        <img src={item.src} alt="" />
                                                        <div className="abs_img" style={{ "backgroundImage": `url(${item.src})` }}></div>
                                                    </div>
                                                    <div className="title">
                                                        <h3>{item.name}</h3>
                                                    </div>
                                                    
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })}

                                </Swiper>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /Service Query Shortcode --> */}
                    <br/>                    
                </>
            )
        }
        else if (lists.length == 0) {
            return ('')
        }
    }
}
export default Partners;
