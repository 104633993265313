import React, { Component } from "react";
import { Link } from "react-router-dom";
import PhotoGallery from './Image/index';
import axios from 'axios';
import qs from 'qs';
import {APIURL} from '../../matcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
class Gallery extends Component {
    constructor(props) 
    {
        super(props)
    }

    render() 
    {
       return (
    <>
        {/* Gallery Page */}
        <div className="fn_cs_gallery_grid fn_cs_lightgallery" data-cols="4">
          <div className="inner">
            <PhotoGallery/>
          </div>
        </div>
        {/* /Gallery Page */}

    </>
  )
    }
}
export default Gallery;
