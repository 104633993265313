import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from 'axios';
import qs from 'qs';
import {APIURL} from '../../../matcher';
// Import Swiper styles
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import slider1 from "../../assets/img/slider/1.jpg";
import slider2 from "../../assets/img/slider/2.jpg";
import slider3 from "../../assets/img/slider/3.jpg";
import slider4 from "../../assets/img/slider/4.jpg";
import slider5 from "../../assets/img/slider/5.jpg";
SwiperCore.use([Navigation, Pagination, Autoplay]);

// Import images


class HomeSlider extends Component {
    constructor(props) 
    {
        super(props);
        this.state = {
          lists: ''
        };
    }

    componentDidMount() 
    {  
        axios.get(APIURL+'slider-list')
        .then((res) => 
        {
            if(res.data.list.length > 0)
            {
              this.setState({
                lists: res.data.list,
                loading: false,
              });
            }
            else
            {
              this.setState({
                lists: '',
                loading: false,
              });
            }
        })
    }

    render() 
    {
        const { lists } = this.state;
        if(lists.length != 0)
        {
            console.log(lists.length)
            return (
                <>
                    <div className="industify_slider_alpha" data-desc-show="yes" data-category-show="yes" data-nav-types="square" data-autoplay-switch="enabled" data-autoplay-time="8000" data-effect="cards" data-progress="enabled" data-box-pos="cr" data-img-effect="enabled" data-text-effect="enabled">
                        {/* <!-- Alpha Slider: navigation --> */}
                        <div className="owl_control">
                            <div className="fn_prev"><span><span className="a"></span><span className="b"></span><span className="c"></span></span></div>
                            <div className="fn_next"><span><span className="a"></span><span className="b"></span><span className="c"></span></span></div>
                        </div>
                        {/* <!-- /Alpha Slider: navigation --> */}
                        {/* <!-- Alpha Slider: pagination --> */}
                        <div className="swiper-pagination"></div>
                        {/* <!-- /Alpha Slider: pagination --> */}
                        {/* <!-- Alpha Slider: wrapper --> */}
                        <Swiper
                            spaceBetween={2}
                            slidesPerView={1}
                            autoplaydisableoninteraction={false}
                            loop={true}
                            pagination={{ clickable: true }}
                            navigation={{
                                prevEl: ".fn_prev",
                                nextEl: ".fn_next",
                            }}
                            autoplay={{
                                "delay": 3000,
                                "disableOnInteraction": false
                            }}
                            className="custom-class"
                        >
                            {
                                lists.map((list) =>
                                {
                                  return(
                                    <SwiperSlide>
                                        <div className="item">
                                            <div className="img_holder" style={{ "backgroundImage": `url(${list.image})` }}></div>
                                            <div className="title_holder">
                                                <div className="inner">
                                                    <div className="in">
                                                        <p><span>{list.heading}</span></p>
                                                        <h3><span>{list.sub_heading}</span></h3>
                                                        <div className="desc"><span>{list.description}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                                })
                            }
                        </Swiper>
                        {/* <!-- Alpha Slider: wrapper --> */}

                    </div>
                    {/* <!-- /Alpha Slider --> */}
                </>
            )
        }
        else if(lists.length == 0)
        {
            return('')
        }
    }
}
export default HomeSlider;
