import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Location } from '../../assets/svg/icon';
import Sidebar from '../sidebar';
import Img from "../../assets/img/blog/1.jpg"
import axios from 'axios';
import qs from 'qs';
import {APIURL} from '../../../matcher';
class NewsArticles extends Component {
    constructor(props) 
    {
        super(props);
        this.state = {
          articles:[]
        };
    }

    componentDidMount() 
    {  
        axios.get(APIURL+'articles-list')
        .then((res) => 
        {
            if(res.data.list.length > 0)
            {
              this.setState({
                articles: res.data.list,
                loading: false,
              });
            }
            else
            {
              this.setState({
                articles: '',
                loading: false,
              });
            }
        })
    }

    render() 
    {
        const { articles } = this.state;
        console.log(articles)
            return (
                <>
                <div className="industify_fn_pagetitle">
                    <div className="container">
                        <div className="title_holder">
                            <h3>News &amp; Articles</h3>
                            <div className="industify_fn_breadcrumbs">
                                <ul>
                                    <li><Link href="/"><a title="Home">Home</a></Link></li>
                                    <li className="separator"><span></span></li>
                                    <li><span className="bread-current">News &amp; Articles</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /Page Title */}


                {/* Sidebar Page */}
                <div className="industify_fn_sidebarpage">
                    <div className="container">
                        <div className="s_inner">
                            {/* Main Sidebar: Left */}
                            <div className="industify_fn_leftsidebar">
                                <ul className="industify_fn_postlist">
                                {
                                    articles.map((article) =>
                                    {
                                      return(
                                        <li>
                                            <div className="post has-post-thumbnail">
                                                <div className="time"><span></span><h3>{article.date}</h3><h5>{article.month}</h5><h5>{article.year}</h5></div>
                                                <div className="img_holder">
                                                    <Link to={'news-articles/'+article.url}><a><img src={article.image} alt="" /></a></Link>
                                                    <span className="shape1"></span><span className="shape2"></span>
                                                </div>
                                                <div className="content_holder">
                                                    <div className="info_holder">
                                                        <p>
                                                            <span className="t_author">By <Link to="javascript:void(0)"ink><a target="_blank" rel="noreferrer">{article.author}</a></Link></span>
                                                            {/*<span className="t_category">In Australia</span>*/}
                                                        </p>
                                                    </div>
                                                    <div className="title">
                                                        <h3><Link to={'news-articles/'+article.url}><a>{article.name}</a></Link></h3>
                                                    </div>
                                                    <div className="excerpt_holder">
                                                        <p>{article.short_description}</p>
                                                    </div>
                                                    <div className="read_holder">
                                                        <p><Link to={'news-articles/'+article.url}><a>Read More</a></Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                  })
                                }
                                </ul>


                                <div className="clearfix"></div>

                                {/*<div className="industify_fn_pagination">
                                    <ul>
                                        <li className="active"><span className="current">1</span></li>
                                        <li><Link href="#"><a>2</a></Link></li>
                                        <li className="view"><p>Viewing page 1 of 2</p></li>
                                    </ul>
                                </div>*/}

                            </div>
                            {/* /Main Sidebar: Left */}

                            {/* Main Sidebar: Right */}
                            <div className="industify_fn_rightsidebar">

                                {/* Get Sidebar */}
                                <Sidebar/>
                                {/* /Get Sidebar */}

                            </div>
                            {/* Main Sidebar: Right */}


                        </div>
                    </div>
                </div>
                {/* /Sidebar Page */}
                </>
            )
    }
}
export default NewsArticles;
