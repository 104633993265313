import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Location } from '../assets/svg/icon';
import axios from 'axios';
import qs from 'qs';
import {APIURL} from '../../matcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faHome, faFax} from '@fortawesome/free-solid-svg-icons';
class ServiceQuery extends Component {
    constructor(props) 
    {
        super(props)
        this.state = {
          name : '',
          phone : '',
          service : '',
          email : '',
          message : '',
          services:[]
        };
    }

    componentDidMount() 
    {  
        axios.get(APIURL+'service-list')
        .then((res) => 
        {
            if(res.data.list.length > 0)
            {
              this.setState({
                services: res.data.list,
                loading: false,
              });
            }
            else
            {
              this.setState({
                services: '',
                loading: false,
              });
            }
        })
    }

    handleInputChange = (event) => 
    {
      const { value, name } = event.target;
      this.setState({
        [name]: value
      });
    }

    onSubmit = (event) => 
    {
      event.preventDefault();
      this.btn.setAttribute("disabled", "disabled");
      axios({
          method: 'get',
          url: APIURL+'service-query/'+this.state.name+'/'+this.state.phone+'/'+this.state.email+'/'+this.state.service+'/'+this.state.message,
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        }).then((res) => 
        {
            alert(res.data.message)      
            window.location.reload(false);
        })  
    }

    render() 
    {
        const { services } = this.state;
       return (
        <>
        <div className="industify_fn_pagetitle">
            <div className="container">
                <div className="title_holder">
                    <h3>Service Wise Query</h3>
                    <div className="industify_fn_breadcrumbs">
                        <ul>
                            <li><Link to="/"><a title="Home">Home</a></Link></li>
                            <li className="separator"><span></span></li>
                            <li><span className="bread-current">Service Wise Query</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* /Page Title */}

        {/* Contact */}
        <div className="industify_fn_contact">
            <div className="container">
                <div className="contact_in">
                    <div className="contact_holder">
                        <div className="contact_left">
                            <h3>Get In touch with Us</h3>
                            <form className="contact_form" onSubmit={this.onSubmit} method="get" autoComplete="off">
                                {/*  */}
                                <div className="items">
                                    <div className="item">
                                        <input id="name" value={this.state.name}  onChange={this.handleInputChange} type="text" placeholder="Name" name="name" required/>
                                    </div>
                                    <div className="item">
                                        <input id="phone" type="text" value={this.state.phone}  onChange={this.handleInputChange} minlength="10" maxlength="10" placeholder="Phone Number"  name="phone" required/>
                                    </div>
                                    <div className="item">
                                        <input id="email" type="email" placeholder="Email" value={this.state.email}  onChange={this.handleInputChange} name="email" required/>
                                    </div>
                                    <div className="item">
                                        <select onChange={this.handleInputChange}  name="service" required>
                                            <option>Select Service</option>
                                            {
                                                services.map((service) =>
                                                {
                                                    return(
                                                        <option value={service.name}>{service.name}</option>
                                                        )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="item">
                                        <textarea id="message" placeholder="Message"  name="message"  onChange={this.handleInputChange} required>{this.state.message}</textarea>
                                    </div>
                                    <div className="item">
                                        <button type="submit" ref={btn => { this.btn = btn; }}>Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="contact_right">
                            <div className="fn_cs_location_list">
                                <ul className="list">

                                    <li className="location_item">
                                        <div className="item">
                                            <div className="title_holder">
                                                <span className="icon_wrapper">
                                                    <span className="icon">
                                                        <FontAwesomeIcon icon={faHome} />
                                                    </span>
                                                    <span className="shape"></span>
                                                </span>
                                                <h3>Office Address</h3>
                                            </div>
                                            <div className="content_holder">
                                                <ul>
                                                    <li>SF-01, Toy City Complex, 2nd Floor Ecotech-III, Greater Noida, Gautambudh Nagar-201310 (UP) India</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    
                                    <li className="location_item">
                                        <div className="item">
                                            <div className="title_holder">
                                                <span className="icon_wrapper">
                                                    <span className="icon">
                                                        {/*<Location className="fn__svg" />*/}
                                                        <FontAwesomeIcon icon={faPhone} />
                                                    </span>
                                                    <span className="shape"></span>
                                                </span>
                                                <h3>Phone Number</h3>
                                            </div>
                                            <div className="content_holder">
                                                <ul>
                                                    <li>+91-9650263574 , +91-9013976908</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="location_item">
                                        <div className="item">
                                            <div className="title_holder">
                                                <span className="icon_wrapper">
                                                    <span className="icon">
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </span>
                                                    <span className="shape"></span>
                                                </span>
                                                <h3>Email Address</h3>
                                            </div>
                                            <div className="content_holder">
                                                <ul>
                                                    <li>info@rcrispsolutions.com</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="location_item">
                                        <div className="item">
                                            <div className="title_holder">
                                                <span className="icon_wrapper">
                                                    <span className="icon">
                                                        <FontAwesomeIcon icon={faFax} />
                                                    </span>
                                                    <span className="shape"></span>
                                                </span>
                                                <h3>Landline</h3>
                                            </div>
                                            <div className="content_holder">
                                                <ul>
                                                    <li>0120-2960505 , 0120-2973838</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* /Contact */}

        </>
    )
    }
}
export default ServiceQuery;
