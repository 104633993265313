import React, { Component } from "react";
import { Link } from "react-router-dom";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import SwiperCore, { Navigation } from 'swiper';
import { Arrow_r } from '../../assets/svg/icon';
import axios from 'axios';
import qs from 'qs';
import {APIURL} from '../../../matcher';
// Import images
import service1 from "../../assets/img/service/single/1.jpg"
import service2 from "../../assets/img/service/single/2.jpg"
import service3 from "../../assets/img/service/single/3.jpg"
import service4 from "../../assets/img/service/single/4.jpg"
import service5 from "../../assets/img/service/single/5.jpg"
import service6 from "../../assets/img/service/single/6.jpg"

import Img from "../../assets/img/thumb/480-700.jpg"

const services = [
    {
        link:"/service-single-1",
        img: Img,
        bg: service1,
        title:"Construction Engineering"

    },
    {
        link:"/service-single-2",
        img: Img,
        bg: service2,
        title:"Textiles &amp; Apparel"

    },
    {
        link:"/service-single-3",
        img: Img,
        bg: service3,
        title:"Housewares  Home Decor"

    },
    {
        link:"/service-single-4",
        img: Img,
        bg: service4,
        title:"Medical Devices"

    },
    {
        link:"/service-single-5",
        img: Img,
        bg: service5,
        title:"Energy Commodities"

    },
    {
        link:"/service-single-6",
        img: Img,
        bg: service6,
        title:"Oil and Gas Industry"

    },
]
SwiperCore.use([Navigation]);
class Services extends Component {
    constructor(props) 
    {
        super(props);
        this.state = {
          lists: ''
        };
    }

    componentDidMount() 
    {  
        axios.get(APIURL+'service-list')
        .then((res) => 
        {
            if(res.data.list.length > 0)
            {
              this.setState({
                lists: res.data.list,
                loading: false,
              });
            }
            else
            {
              this.setState({
                lists: '',
                loading: false,
              });
            }
        })
    }

    render() 
    {
        const { lists } = this.state;
        if(lists.length != 0)
        {
            return (
            <>
                {/* <!-- Service Query Shortcode --> */}
                <div className="fn_cs_service_query" data-mobile="disable" data-column-count="4">
                    <div className="top_bar">
                        <div className="t_inner">
                            <h3>Our Services</h3>
                            <span>The automotive industry is the technological trendsetter among manufacturing industries.</span>
                            <div className="owl_control">
                                <div className="fn_prev"></div>
                                <div className="fn_next"></div>
                            </div>
                        </div>
                    </div>
                    <div className="service_part">
                        <div className="owl-carousel">
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={2}
                                autoplaydisableoninteraction={false}
                                loop={true}
                                // pagination={{ clickable: true }}
                                className="custom-class"
                                navigation={{
                                    prevEl: ".fn_prev",
                                    nextEl: ".fn_next",
                                }}
                                breakpoints={{

                                    // when window width is >= 768px

                                    768: {
                                        // when window width is >= 992px
                                        slidesPerView: 3,
                                    },
                                    1200: {
                                        // when window width is >= 992px
                                        slidesPerView: 4,
                                    }
                                }}
                            >
                                 {lists.map((item, i) => {
                                    console.log(item.name)
                                    return(
                                        <SwiperSlide key={i}>
                                            <div className="item">
                                                
                                                <div className="img_holder">
                                                    <img src={item.cover} alt="" />
                                                    <div className="abs_img" style={{ "backgroundImage": `url(${item.image})` }}></div>
                                                </div>
                                                <div className="title">
                                                    <h3>{item.name}</h3>
                                                </div>
                                                <div className="view_more">
                                                    <Link to={'/service/'+item.url}>
                                                    <span className="more_link">
                                                        <span className="text">View More</span>
                                                        <span className="arrow"><Arrow_r className="fn__svg" /></span>
                                                    </span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                 })}

                            </Swiper>
                        </div>
                    </div>
                </div>
                {/* <!-- /Service Query Shortcode --> */}

            </>
            )
        }
        else if(lists.length == 0)
        {
            return('')
        }   
    }
}
export default Services;
