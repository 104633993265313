import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo-light.png";
import Services from '../HomeServices/index';
import Partners from '../Partners/index';
import Slider from '../HomeSlider/index';
import { Arrow_r, PlayVideo, Checked, Stars,Quotes, Certificate } from '../../assets/svg/icon';
import right from "../../assets/img/about/main.jpg"
import rightImage from "../../assets/img/service/choose.jpg"
import testimonial from "../../assets/img/testimonial/solar.jpg"
import portfolio1 from "../../assets/img/portfolio/1.jpg"
import portfolio2 from "../../assets/img/portfolio/2.jpg"
import portfolio3 from "../../assets/img/portfolio/3.jpg"
import portfolio4 from "../../assets/img/portfolio/4.jpg"
import map from "../../assets/img/blog/map.png"
import blog1 from "../../assets/img/blog/1.jpg"
import blog2 from "../../assets/img/blog/2.jpg"
import blog3 from "../../assets/img/blog/3.jpg"
import Img from "../../assets/img/thumb/370-250.jpg"
import Imge from "../../assets/img/thumb/700-500.jpg"
import Image from "../../assets/img/thumb/main.jpg"

import axios from 'axios';
import qs from 'qs';
import {APIURL} from '../../../matcher';
class Home extends Component {
    constructor(props) 
    {
        super(props);
        this.state = {
          whys: '',
          articles:[]
        };
    }

    componentDidMount() 
    {  
        axios.get(APIURL+'why-us')
        .then((res) => 
        {
            if(res.data.list.length > 0)
            {
              this.setState({
                whys: res.data.list,
                loading: false,
              });
            }
            else
            {
              this.setState({
                whys: '',
                loading: false,
              });
            }
        })

        axios.get(APIURL+'articles-list')
        .then((res) => 
        {
            if(res.data.list.length > 0)
            {
              this.setState({
                articles: res.data.list,
                loading: false,
              });
            }
            else
            {
              this.setState({
                whys: '',
                loading: false,
              });
            }
        })
    }

    render() 
    {
        const { whys, articles } = this.state;
        if(whys.length != 0)
        {
           return (
                <>
                        <Slider />
                        {/* <!-- Principles Modern --> */}
                        <div className="fn_cs_principles_modern">
                            <div className="container">
                                <div className="inner">
                                    <div className="shape"><span className="shape1"></span><span className="shape2"></span></div>
                                    <ul className="fn_cs_miniboxes">
                                        {
                                            whys.map((list) =>
                                            {
                                              return(
                                                <li>
                                                    <div className="item">
                                                        <div className="title_holder">
                                                            <h3>{list.heading}</h3>
                                                            <p>{list.content}</p>
                                                            <span className="icon">
                                                                <Arrow_r className="fn__svg" />
                                                            </span>
                                                        </div>
                                                        <div className="number_holder">0{list.id}</div>
                                                    </div>
                                                </li>
                                            )
                                            })
                                        }      
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <!-- /Principles Modern --> */}
                        {/* <!-- About Section --> */}
                        <div className="about_section">
                            {/* <!-- About Shortcode --> */}
                            <div className="fn_cs_about">
                                <div className="container">
                                    <div className="a_inner">
                                        <div className="leftpart">
                                            <div className="title_holder">
                                                <h3 className="title">We are Industify</h3>
                                                <p>Established in 2019, We RCrisp Solutions India Pvt, are well known in the field of powder coating solutions and solar power solutions, owing to our expertise in designing, engineering and installing all kinds of solar power plant. Our array includes different types of powder coating production packages and variety of powder coating. </p>
                                                <p>RCrisp is synonymous with providing solution to esteemed Customer base. We deliver all solutions under one umbrella with commitment and dedication. Company's vision is to provide environment friendly engineering solutions with global footprint, delivering sustainable value for money to all our esteemed customers.</p>
                                            </div>
                                            <div className="sign_holder">
                                                <h3 className="name">S.K. Tyagi</h3>
                                                <p className="occ">Chief Executive</p>
                                            </div>
                                        </div>
                                        <div className="rightpart">
                                            <div className="r_inner" id="scene">
                                                <div className="layer border" data-depth="0.3">
                                                    <span className="span1"></span>
                                                    <span className="span2"></span>
                                                    <img src={Image} alt="" />
                                                </div>
                                                <div className="img_holder layer" data-depth="0.5">
                                                    <img src={Image} alt="" />
                                                    <div className="abs_img" style={{ "backgroundImage": `url(${right})` }}></div>
                                                </div>
                                                <div className="dots layer" data-switch="disable" data-depth="0.9">
                                                    <img src={Image} alt="" />
                                                </div>
                                            </div> 
                                            {/*<div className="r_inner" id="scene" style={{ "transform": "translate3d(0px, 0px, 0px)", "transformStyle": "preserve-3d", "backfaceVisibility": "hidden" }}>
                                                <div className="layer border" data-depth="0.3" style={{ "position": "relative", "display": "block", "left": "0px", "top": "0px", "transform": "translate3d(21.2782px, -19.0075px, 0px)", "transformStyle": "preserve-3d", "backfaceVisibility": "hidden" }}>
                                                    <span className="span1"></span>
                                                    <span className="span2"></span>
                                                    <img src={Image} alt="" />
                                                </div>
                                                <div className="img_holder layer" data-depth="0.5" style={{ "position": "absolute", "display": "block", "left": "0px", "top": "0px", "transformStyle": "preserve-3d", "backfaceVisibility": "hidden", "transform": "translate3d(35.4636px, -31.6792px, 0px)" }}>
                                                    <img src={Image} alt="" />
                                                    <div className="abs_img" data-bg-img="img/about/right.jpg" style={{ "backgroundImage": "url(img/about/right.jpg)" }}></div>
                                                </div>
                                                <div className="dots layer" data-switch="disable" data-depth="0.9" style={{ "position": "absolute", "display": "block", "left": "0px", "top": "0px", "transform": "translate3d(63.8345px, -57.0226px, 0px)", "transformStyle": "preserve-3d", "backfaceVisibility": "hidden" }}>
                                                    <img src={Image} alt="" />
                                                </div>
                                            </div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /About Shortcode --> */}
                        </div>
                        {/* <!-- /About Section --> */}
                        <Services />
                        {/* <!-- Service Section --> */}
                        <div className="service_section">
                            {/* <!-- About with Rating Shortcode --> */}
                            <div className="fn_cs_about_with_rating">
                                <div className="container">
                                    <div className="awr_inner">
                                        <div className="left_part">
                                            <div className="title_holder">
                                                <h3>Why choose our services?</h3>
                                                <p>At Industify Company, we rely on honesty, discipline and hard work and believe our success can be attributed to upholding a simple set of core values that date back to the beginning of the company.</p>
                                                <p>Industify is an integrated design-build firm offering engineering, architecture and construction services to domestic and international customers throughout the U.S. Unique to Industify is the fact that we conduct all disciplines in-house in a collaborative environment.</p>
                                            </div>
                                            <div className="signature">
                                                <Certificate className="fn__svg" />
                                                <p>We have a<br />ISO Certificate.</p>
                                            </div>
                                        </div>
                                        <div className="right_part">
                                            <div className="r_inner">
                                                <div className="top_section">
                                                    <div className="badge_holder">
                                                        <div className="badge_left">
                                                            <div className="b_title">
                                                                <h3>World&apos;s Leading Industry<br />Corporation</h3>
                                                            </div>
                                                            <div className="b_desc"><span className="year">47</span><span className="text">Years of Experience</span></div>
                                                        </div>
                                                        <div className="badge_right" style={{ "backgroundImage": `url(${rightImage})` }}></div>
                                                    </div>
                                                    <div className="rating_holder">
                                                        <div className="r_header"></div>
                                                        <div className="r_footer"></div>
                                                        <Link href="#"><a></a></Link>
                                                        <Stars className="fn__svg" />
                                                        <h3 className="rating_number">9.7</h3>
                                                        <h3 className="rating_text">Customer Rating</h3>
                                                    </div>
                                                </div>
                                                <div className="bottom_section">
                                                    <div className="list">
                                                        <ul>
                                                            <li>
                                                                <div className="item">
                                                                    <span className="icon">
                                                                        <Checked className="fn__svg" />
                                                                    </span>
                                                                    <p>Best talent in the industry business.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="item">
                                                                    <span className="icon">
                                                                        <Checked className="fn__svg" />
                                                                    </span>
                                                                    <p>One of the most expirienced company.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="item">
                                                                    <span className="icon">
                                                                        <Checked className="fn__svg" />
                                                                    </span>
                                                                    <p>We have completed over 3000 projects.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="item">
                                                                    <span className="icon">
                                                                        <Checked className="fn__svg" />
                                                                    </span>
                                                                    <p>Most dedicated and passionate team.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="item">
                                                                    <span className="icon">
                                                                        <Checked className="fn__svg" />
                                                                    </span>
                                                                    <p>Company has over 2000 workers.</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /About with Rating Shortcode --> */}
                        </div>
                        {/* <!-- /Service Section --> */}
                        <Partners />
                        <br/>
                        {/* <!-- Call to Action --> */}
                        <div className="fn_cs_call_to_action">
                            <div className="container">
                                <div className="cta_holder">
                                    <div className="title_holder">
                                        <h3>We will Make Your Dream Come True</h3>
                                        <p>We are focused on sustainable business that delivers the best possible project results.</p>
                                    </div>
                                    <div className="link_holder">
                                        <Link href="/contact"><a>Our Responsibility</a></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- /Call to Action --> */}
                        {/* <!-- Testimonial Section --> */}
                        <div className="testimonial_section" style={{ "backgroundImage": `url(${testimonial})` }}>
                            <div className="overlay"></div>
                            {/* <!-- Single Testimonial Shortcode --> */}
                            <div className="fn_cs_single_testimonial">
                                <div className="container">
                                    <div className="inner">
                                        <Quotes className="fn__svg"/>
                                        <div className="content_holder">
                                            <p>Hiday Motors needed to build a brand new 28,000 sf facility that would both meet our needs and comply with GMs standards - which seemed daunting, to say the least. Industify alleviated all of our concerns and communicated with us every step of the way. We have received dozens of compliments from our customers, and our employees love the new store!</p>
                                            <h3>Steve Lehman</h3>
                                            <h5>CEO of Hiday Motors.</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /Single Testimonial Shortcode --> */}
                        </div>
                        {/* <!-- /Testimonial Section --> */}
                        {/* <!-- Project Sticky Full --> */}
                        <div className="fn_cs_project_sticky_full">
                            <div className="inner">
                                <div className="left_part">
                                    <div className="fn_cs_sticky_section">
                                        <h3>Our latest News & Articles.</h3>
                                        <p>At Industify Company, we rely on honesty, discipline and hard work and believe our success can be attributed to upholding a simple set of core values that date back to the beginning of the company.</p>
                                        <p>Industify is an integrated design-build firm offering engineering, architecture and construction services to domestic and international customers throughout the U.S. Unique to Industify is the fact that we conduct all disciplines in-house in a collaborative environment.</p>
                                        <Link href="/news-articles"><a>View All News & Articles</a></Link>
                                    </div>
                                </div>

                                <div className="right_part">
                                    <div className="fn_cs_sticky_section">
                                        <ul>
                                        {
                                            articles.map((article) =>
                                            {
                                              return(
	                                            <li>
	                                                <div className="item">
	                                                    <div className="img_holder">
	                                                        <img src={article.image} alt={article.image_alt} />
	                                                        <div className="abs_img" style={{ "backgroundImage": `url(${article.image})` }}></div>
	                                                        <Link to={'news-articles/'+article.url}><a></a></Link>
	                                                    </div>
	                                                    <div className="title_holder">
	                                                        <h3><Link to={'news-articles/'+article.url}><a>{article.name}</a></Link></h3>
	                                                        <span className="desc">{article.short_description}</span>
	                                                        <p>
	                                                            <Link to={'news-articles/'+article.url}><a>
	                                                                <span className="text">More Details</span>
	                                                                <span className="arrow">
	                                                                    <Arrow_r className="fn__svg" />
	                                                                </span>
	                                                            </a></Link>
	                                                        </p>
	                                                    </div>
	                                                </div>
	                                            </li>
                                           		)
                                          	})
                                        }
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <!-- /Project Sticky Full --> */}
                        {/* <!-- Blog Section --> */}           
                        {/* <!-- /Blog Section --> */}
                </>
            )
        }
        else if(whys.length == 0)
        {
            return('')
        }
    }
}
export default Home;
