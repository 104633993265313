import React, { Component } from "react";
import { Link } from "react-router-dom";
import Img from "../../assets/img/footer/bg.jpg";
import logo from "../../assets/img/logo-light.png";
import { Facebook, Instagram, Linkedin, Twitter } from '../../assets/svg/social/IconSocial';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faHome} from '@fortawesome/free-solid-svg-icons';
const background = "../../assets/img/call.png"
class Footer extends Component {
    constructor(props) 
    {
        super(props);
    }

    render() 
    {
        return (
        <>
            <footer className="industify_fn_footer">
                <div className="top_footer">
                    <div className="top_footer_img"></div>
                    {/* <!-- TRIPLE WIDGET --> */}
                    <div className="footer_widget">
                        <div className="container">
                            <div className="inner">
                                <ul className="widget_area">
                                    <li>
                                        <div className="item">
                                            <div className="logo">
                                                <Link href="/"><a><img src={logo} alt="" /></a></Link>
                                            </div>
                                            <div className="textwidget">
                                                <p>We are focused on user-driven outcomes, we strive to create meaningful connections for users through considered strategy and innovation.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item">
                                            <div className="wid-title"><span>Helpful Links</span></div>
                                            <div className="widget_nav_menu">
                                                <ul className="menu">
                                                    <li><Link to="/about-us"><a>About Us</a></Link></li>
                                                    <li><Link to="/our-services"><a>Our Services</a></Link></li>
                                                    <li><Link to="/contact-us"><a>Contact Us</a></Link></li>
                                                    <li><Link to="/news-articles"><a>News & Articles</a></Link></li>                                                
                                                    <li><Link to="/gallery"><a>Gallery</a></Link></li>                                                
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item">
                                            <div className="wid-title"><span>RCrisp Solutions Pvt. Ltd.</span></div>
                                            <div className="widget_nav_menu">
                                                <ul className="menu" style={{'width':'105%'}}>
                                                    <li><FontAwesomeIcon icon={faPhone} />&nbsp;+91-9650263574</li><br/>
                                                    <li style={{'width':'58%'}}><FontAwesomeIcon icon={faEnvelope} />&nbsp;info@rcrispsolutions.com</li>
                                                    <br/>
                                                    <li style={{'width':'69%'}}><FontAwesomeIcon icon={faHome} />&nbsp;SF-01, Toy City Complex,2nd Floor Ecotech-III, Greater Noida,<br/>Gautambudh Nagar-201310 (UP) India</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /TRIPLE WIDGET --> */}
                </div>
                {/* <!-- BOTTOM --> */}
                <div className="footer_bottom">
                    <div className="container">
                        <div className="footer_bottom_in">
                            <div className="footer_copyright">
                                <p>2022 &copy; All rights reserved by <Link href="https://iteconic.com/"><a target="_blank" rel="noreferrer">ITEconic</a></Link></p>
                            </div>
                            <Link href="#">
                                <a className="industify_fn_totop">
                                    <span className="top"></span>
                                    <span className="text">To Top</span>
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* <!-- /BOTTOM --> */}
            </footer>
        </>
    )
    }
}
export default Footer;
