import React from 'react'

function Arrow_r({ className }) {
    return (

        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 31.49 31.49" style={{ "enableBackground": "new 0 0 31.49 31.49" }} xmlSpace="preserve">
            <path d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111
	C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587
	c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z"/>
        </svg>
    )
}
function Certificate({ className }) {
    return (

        <svg className={className}  enableBackground="new 0 0 512.531 512.531" height="512" viewBox="0 0 512.531 512.531" width="512" xmlns="http://www.w3.org/2000/svg">
            <g><g>
                <path d="m319.923 317.569c1.595.748 3.282 1.115 4.953 1.115 2.644 0 5.25-.918 7.392-2.701l10.424-8.673 10.425 8.673c3.497 2.909 8.228 3.516 12.345 1.585 4.118-1.931 6.676-5.956 6.676-10.504v-49.064c8.94-8.062 14.57-19.726 14.57-32.683 0-24.271-19.745-44.016-44.016-44.016s-44.016 19.745-44.016 44.016c0 12.957 5.63 24.622 14.57 32.683v49.064c.001 4.549 2.559 8.574 6.677 10.505zm37.215-17.753-7.026-5.845c-4.299-3.577-10.54-3.576-14.84 0l-7.026 5.845v-32.931c4.528 1.578 9.386 2.447 14.446 2.447s9.917-.869 14.446-2.447zm-14.446-103.514c16 0 29.016 13.016 29.016 29.016 0 15.999-13.016 29.015-29.016 29.015s-29.016-13.016-29.016-29.015c.001-16 13.017-29.016 29.016-29.016z" />
                <path d="m431.295 0h-350.059c-15.117 0-27.416 12.299-27.416 27.417v457.698c0 15.118 12.299 27.417 27.416 27.417h350.059c15.118 0 27.417-12.299 27.417-27.417v-457.698c-.001-15.118-12.299-27.417-27.417-27.417zm12.416 485.115c0 6.847-5.57 12.417-12.417 12.417h-350.058c-6.846 0-12.416-5.57-12.416-12.417v-457.698c0-6.847 5.57-12.417 12.416-12.417h350.059c6.847 0 12.417 5.57 12.417 12.417v457.698z" />
                <path d="m409.084 36.978h-305.637c-6.615 0-11.997 5.381-11.997 11.996v282.873c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-279.869h299.63v408.575h-299.63v-93.392c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v96.396c0 6.615 5.382 11.996 11.997 11.996h305.637c6.615 0 11.997-5.381 11.997-11.996v-414.583c0-6.615-5.382-11.996-11.997-11.996z" />
                <path d="m386.708 98.296c0-8.507-6.921-15.428-15.428-15.428h-230.029c-8.507 0-15.428 6.921-15.428 15.428v28.598c0 8.507 6.921 15.428 15.428 15.428h160.912c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-160.912c-.236 0-.428-.192-.428-.428v-28.598c0-.236.192-.428.428-.428h230.029c.236 0 .428.192.428.428v28.598c0 .236-.192.428-.428.428h-34.698c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h34.698c8.507 0 15.428-6.921 15.428-15.428z" />
                <path d="m170.645 398.259c-6.111 13.596-2.019 20.224 2.49 23.391 10.561 7.415 23.625-4.294 29.904-9.923l.067-.06c2.023 4.612 5.426 8.277 9.813 10.5 10.943 5.546 20.1.046 26.736-6.933 1.565 2.867 3.538 5.374 5.883 7.434 5.915 5.195 14.424 6.998 23.389 6.998 8.043 0 16.455-1.452 23.68-3.207 4.025-.978 6.496-5.033 5.518-9.058s-5.033-6.498-9.058-5.518c-15.985 3.882-28.871 3.696-33.63-.484-4.124-3.623-4.929-9.92-5.042-13.351-.109-3.297-2.359-6.136-5.545-6.995-3.184-.859-6.558.465-8.308 3.262-7.563 12.079-12.756 16.545-16.843 14.473-1.592-.807-2.692-2.256-3.27-4.308-.88-3.126-.324-7.084 1.383-9.85 2.176-3.524 1.083-8.145-2.441-10.321s-8.146-1.083-10.321 2.44c-3.251 5.264-7.428 9.708-12.022 13.809-5.261 4.716-8.398 6.826-10.228 7.75.746-2.816 3.273-8.277 10.458-18.836 7.49-10.998 15.109-22.006 23.09-32.749 0 0 .152-.205.152-.205 2.352-3.164 1.854-7.607-1.14-10.172-2.994-2.563-7.462-2.373-10.226.438-17.068 17.357-44.733 25.562-70.475 20.909-4.076-.738-7.978 1.97-8.715 6.046s1.97 7.978 6.046 8.715c17.271 3.123 34.902 1.504 50.723-4.205-.091.142-1.205 1.828-1.858 2.788-2.72 3.994-7.269 10.679-10.21 17.222z" />
                <path d="m233.245 181.302c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h23.021c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
                <path d="m133.323 196.302h62.595c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-62.595c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z" />
                <path d="m133.323 230.946h122.942c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-122.942c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z" />
                <path d="m263.766 258.091c0-4.142-3.358-7.5-7.5-7.5h-60.348c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h60.348c4.142 0 7.5-3.358 7.5-7.5z" />
                <path d="m133.323 265.591h26.173c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-26.173c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z" />
                <path d="m133.323 300.236h93.656c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-93.656c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z" />
            </g></g>
        </svg>


    )
}


function Check({ className }) {
    return (

        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="459px" height="459px" viewBox="0 0 459 459" style={{ "enableBackground": "new 0 0 459 459" }} xmlSpace="preserve">
            <g>
                <g id="check-box-outline">
                    <path d="M124.95,181.05l-35.7,35.7L204,331.5l255-255l-35.7-35.7L204,260.1L124.95,181.05z M408,408H51V51h255V0H51
			C22.95,0,0,22.95,0,51v357c0,28.05,22.95,51,51,51h357c28.05,0,51-22.95,51-51V204h-51V408z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>

    )
}

function Checked({ className }) {
    return (
        <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" xmlnsXlink="http://www.w3.org/1999/xlink" enableBackground="new 0 0 26 26">
            <path d="m.3,14c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l.1,.1 5.5,5.9c0.2,0.2 0.5,0.2 0.7,0l13.4-13.9h0.1v-8.88178e-16c0.4-0.4 1-0.4 1.4,0l1.4,1.4c0.4,0.4 0.4,1 0,1.4l0,0-16,16.6c-0.2,0.2-0.4,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-7.8-8.4-.2-.3z" />
        </svg>
    )
}

function FileDoc({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="588.601px" height="588.6px" viewBox="0 0 588.601 588.6" style={{ "enableBackground": "new 0 0 588.601 588.6" }}
            xmlSpace="preserve">
            <g>
                <path d="M181.285,256.98c-15.915,0.274-25.088,15.952-25.088,36.698c0,20.903,9.471,35.807,25.239,36.08
           c16.158,0.29,25.537-15.445,25.537-36.713C206.967,273.412,197.749,256.701,181.285,256.98z"/>
                <path d="M68.262,259.221c-4.736,0.076-7.781,0.577-9.574,1.062v66.564c1.793,0.484,4.704,0.543,7.338,0.585
           c19.388,0.485,32.234-10.99,32.234-35.78C98.405,270.103,86.688,258.902,68.262,259.221z"/>
                <path d="M359.031,537.78c0.781,0.042,1.551,0.111,2.342,0.111h178.2c20.846,0,37.8-16.96,37.8-37.801V86.994
           c0-20.838-16.954-37.8-37.8-37.8h-178.2c-0.786,0-1.561,0.077-2.342,0.113V0L11.228,46.417v494.564L359.031,588.6V537.78z
            M361.373,70.798h178.2c8.933,0,16.2,7.27,16.2,16.2v413.103c0,8.928-7.268,16.2-16.2,16.2h-178.2
           c-0.796,0-1.571-0.127-2.342-0.242V383.991h160.145c3.955,0,7.161-3.201,7.161-7.15c0-3.955-3.206-7.151-7.161-7.151H359.031
           v-43.886h160.145c3.955,0,7.161-3.206,7.161-7.15c0-3.955-3.206-7.15-7.161-7.15H359.031V268.98h160.145
           c3.955,0,7.161-3.199,7.161-7.151c0-3.952-3.206-7.148-7.161-7.148H359.031v-42.926h75.252c3.945,0,7.151-3.201,7.151-7.151
           c0-3.952-3.206-7.153-7.151-7.153h-75.252V71.036C359.802,70.922,360.571,70.798,361.373,70.798z M105.464,333.06
           c-10.223,8.87-25.574,12.762-44.01,12.276c-10.887-0.295-18.512-1.239-23.67-2.12V244.35c7.604-1.54,17.595-2.544,28.242-2.813
           c17.961-0.472,29.787,2.764,39.15,10.102c10.18,7.868,16.611,20.692,16.611,39.227C121.788,310.954,114.903,324.707,105.464,333.06
           z M180.178,349.123c-30.649-0.817-48.157-24.928-48.157-54.986c0-31.622,19.322-55.764,49.884-56.576
           c32.74-0.862,51.057,24.113,51.057,54.662C232.962,328.525,211.283,349.951,180.178,349.123z M308.27,330.961
           c8.068,0.137,17.107-1.424,22.465-3.392l4.103,20.53c-5.01,2.277-16.189,4.556-30.586,4.166
           c-39.92-1.055-59.927-25.534-59.927-57.244c0-37.966,27.433-59.827,62.543-60.75c13.9-0.367,24.574,2.102,29.415,4.39
           l-5.548,20.909c-5.526-2.144-13.152-4.076-22.645-3.921c-20.975,0.351-37.009,12.825-37.009,37.9
           C271.081,316.122,284.705,330.555,308.27,330.961z"/>
                <path d="M477.394,226.081l6.629-26.935c1.693-6.544,2.806-12.062,3.829-18.183h0.163c0.675,6.199,1.692,11.639,3.138,18.183
           l5.959,26.935h11.222l15.367-57.268h-10.621l-5.948,25.917c-1.529,6.963-2.89,13.505-3.907,19.796h-0.18
           c-0.839-6.204-2.035-12.577-3.476-19.377l-5.695-26.336h-10.958l-6.196,25.917c-1.624,7.219-3.254,14.017-4.171,20.049h-0.158
           c-0.938-5.693-2.3-13.004-3.75-19.965l-5.437-25.995h-11.127l14.022,57.267h11.295V226.081z"/>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}

function FileMp3({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="585.918px" height="585.918px" viewBox="0 0 585.918 585.918" style={{ "enableBackground": "new 0 0 585.918 585.918" }}
            xmlSpace="preserve">
            <g>
                <path d="M195.365,245.863c-4.935,0.092-8.273,0.631-10.003,1.158V279.3c2.048,0.488,4.591,0.646,8.084,0.635
           c12.987-0.013,21.092-6.779,21.092-18.121C214.538,251.617,207.577,245.653,195.365,245.863z"/>
                <path d="M357.396,535.325c0.776,0.052,1.542,0.114,2.329,0.114h177.39c20.75,0,37.627-16.883,37.627-37.627V86.597
           c0-20.743-16.877-37.628-37.627-37.628h-177.39c-0.781,0-1.553,0.07-2.329,0.118V0L11.176,46.206v492.311l346.22,47.401V535.325z
            M359.726,70.476h177.39c8.893,0,16.125,7.236,16.125,16.126v411.22c0,8.888-7.232,16.127-16.125,16.127h-177.39
           c-0.792,0-1.563-0.121-2.329-0.243V307.625c11.039,35.896,44.493,62.07,83.979,62.07c7.477,0,14.688-1.035,21.617-2.799
           c4.032-5.506,10.684-9.605,18.667-11.333c2.719-0.587,5.25-0.924,8.242-0.883l0.011-10.011
           c-13.449,10.421-30.258,16.683-48.537,16.683c-43.843,0-79.496-35.658-79.496-79.488c0-43.833,35.653-79.497,79.496-79.497
           c43.686,0,79.225,35.433,79.466,79.061c2.205,1.554,4.924,3.371,8.116,5.417c0.095-1.661,0.252-3.3,0.252-4.981
           c0-48.439-39.401-87.852-87.834-87.852c-39.485,0-72.939,26.179-83.979,62.069V70.711
           C358.162,70.594,358.929,70.476,359.726,70.476z M121.34,335.91l-1.488-40.6c-0.441-12.743-0.892-28.142-0.892-43.549l-0.452,0.011
           c-3.107,13.546-7.247,28.609-11.058,40.961l-11.982,40.813l-17.125-0.431l-10.234-40.252c-3.086-12.107-6.333-26.772-8.57-40.189
           h-0.293c-0.549,13.9-0.969,29.765-1.672,42.573l-1.671,38.992l-19.486-0.493l5.9-101.837l28.344-0.736l9.375,34.446
           c3.026,12.019,6.037,24.998,8.187,37.245l0.431,0.005c2.753-12.073,6.079-25.887,9.272-37.562l10.531-35.119l29.672-0.774
           l5.617,107.067L121.34,335.91z M229.111,287.492c-8.536,8.071-21.04,11.599-35.5,11.499c-3.181-0.021-6.047-0.2-8.25-0.551v39.097
           l-23.629-0.598V230.254c7.293-1.467,17.624-2.714,32.359-3.097c15.11-0.397,26.005,2.286,33.375,8.047
           c7.105,5.488,11.917,14.714,11.917,25.696C239.384,271.883,235.735,281.192,229.111,287.492z M283.87,341.925
           c-14.455-0.382-26.52-4.394-32.934-8.567l5.23-18.991c4.57,2.415,15.137,6.96,25.807,7.149c13.745,0.23,20.767-6.147,20.767-14.54
           c0-10.962-11.213-15.959-22.835-16.001l-10.643-0.037v-18.383l10.132-0.05c8.832-0.21,20.191-3.515,20.191-12.922
           c0-6.661-5.607-11.536-16.735-11.355c-9.143,0.143-18.707,4.163-23.287,6.914l-5.252-18.363c6.607-4.335,19.906-8.704,34.426-9.068
           c24.449-0.601,38.234,11.683,38.234,27.082c0,11.94-7.004,21.312-21.229,26.149v0.347c13.869,2.402,25.192,12.624,25.192,27.361
           C330.929,328.592,312.838,342.686,283.87,341.925z"/>
                <path d="M503.057,275.248c-5.502,0.563-6.068,4.23-6.068,4.23v83.183c-4.137-1.26-9.05-1.491-14.111-0.39
           c-11.19,2.426-18.509,10.53-16.366,18.064c1.249,6.954,12.975,11.637,24.157,9.154c9.826-2.138,16.672-8.646,16.724-15.312h0.021
           l0.252-77.13c0,0,19.634-2.787,38.847,23.607c0,0,0.052-13.623-14.73-23.598C502.951,279.418,503.057,275.248,503.057,275.248z"/>
                <path d="M441.375,264.169c-9.753,0-17.689,7.929-17.689,17.685c0,9.75,7.937,17.689,17.689,17.689
           c9.754,0,17.681-7.938,17.681-17.689C459.056,272.098,451.129,264.169,441.375,264.169z"/>
                <path d="M441.375,248.014c-18.656,0-33.837,15.181-33.837,33.84c0,18.653,15.181,33.834,33.837,33.834
           c18.646,0,33.828-15.182,33.828-33.834C475.203,263.194,460.021,248.014,441.375,248.014z M441.375,307.331
           c-14.047,0-25.491-11.432-25.491-25.477c0-14.053,11.444-25.486,25.491-25.486c14.058,0,25.481,11.433,25.481,25.486
           C466.856,295.899,455.433,307.331,441.375,307.331z"/>
                <path d="M515.444,310.476l-0.062,18.493c3.774-5.932,6.872-12.325,9.16-19.106c-2.798-1.711-5.35-2.846-7.564-3.57
           C516.517,307.721,515.97,309.1,515.444,310.476z"/>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}

function FileMp4({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="588.6px" height="588.6px" viewBox="0 0 588.6 588.6" style={{ "enableBackground": "new 0 0 588.6 588.6" }} xmlSpace="preserve"
        >
            <g>
                <path d="M359.036,537.786c0.771,0.042,1.551,0.115,2.342,0.115h178.2c20.84,0,37.8-16.959,37.8-37.8v-413.1
           c0-20.838-16.96-37.8-37.8-37.8h-178.2c-0.786,0-1.571,0.069-2.342,0.114V0L11.222,46.419v494.562L359.036,588.6V537.786z
            M359.036,71.033c0.771-0.113,1.54-0.232,2.342-0.232h178.2c8.933,0,16.199,7.267,16.199,16.2v413.1
           c0,8.934-7.267,16.2-16.199,16.2h-178.2c-0.797,0-1.571-0.127-2.342-0.242V71.033z M115.717,330.676l-1.505-40.972
           c-0.443-12.841-0.886-28.387-0.886-43.934h-0.464c-3.122,13.68-7.291,28.88-11.14,41.331l-12.053,41.19l-17.233-0.422
           l-10.291-40.634c-3.133-12.218-6.394-27.005-8.63-40.547l-0.285,0.011c-0.566,14.019-0.994,30.029-1.695,42.946L49.844,329
           l-19.62-0.496l5.951-102.766l28.529-0.747l9.46,34.765c3.02,12.129,6.064,25.229,8.234,37.57l0.433,0.011
           c2.753-12.174,6.117-26.116,9.326-37.897l10.61-35.442l29.855-0.775l5.656,108.013L115.717,330.676z M224.192,281.821
           c-8.585,8.134-21.189,11.709-35.72,11.594c-3.208-0.027-6.096-0.201-8.321-0.56v39.456l-23.783-0.602V224.068
           c7.351-1.484,17.75-2.732,32.579-3.122c15.206-0.398,26.169,2.312,33.605,8.134c7.138,5.524,11.981,14.84,11.981,25.914
           S230.853,275.471,224.192,281.821z M333.207,309.034l-14.164-0.2v27.021l-25.703-0.654v-26.704l-49.718-0.686v-16.686
           l42.335-67.753l33.086-0.799v66.403l14.164,0.066V309.034z"/>
                <path d="M190.233,239.826c-4.97,0.087-8.321,0.643-10.083,1.163v32.566c2.078,0.49,4.627,0.644,8.153,0.644
           c13.081-0.023,21.223-6.848,21.223-18.291C209.526,245.621,202.523,239.609,190.233,239.826z"/>
                <path d="M282.972,264.094l-15.103,24.284v0.34l25.471,0.124v-24.819c0-6.719,0.346-13.614,0.875-20.859l-0.695,0.011
           C289.81,250.464,286.833,257.025,282.972,264.094z"/>
                <path d="M526.489,240.629H374.582v111.32h151.907V240.629z M483.742,297.517l-51.996,33.201c-0.284,0.222-0.622,0.338-0.97,0.338
           l-0.696-0.158c-0.527-0.253-0.875-0.802-0.875-1.403v-66.4c0-0.588,0.348-1.149,0.875-1.402c0.549-0.277,1.191-0.2,1.666,0.171
           l51.996,33.187c0.369,0.316,0.58,0.77,0.58,1.233C484.322,296.757,484.111,297.211,483.742,297.517z"/>
                <path d="M532.574,351.959h-6.085v1.735H374.582v-1.735h-5.505v27.19h163.497V351.959L532.574,351.959z M529.104,375.669H372.558
           V355.43h156.547V375.669z"/>
                <path d="M526.489,238.892v1.737h6.085v-27.187H369.077v27.187h5.505v-1.737H526.489z M372.558,237.157V216.92h156.547v20.236
           H372.558z"/>
                <rect x="378.643" y="221.724" width="23.267" height="9.861" />
                <rect x="408.448" y="221.724" width="23.267" height="9.861" />
                <rect x="438.021" y="221.724" width="23.267" height="9.861" />
                <rect x="467.816" y="221.724" width="23.272" height="9.861" />
                <rect x="498.582" y="222.109" width="23.267" height="9.859" />
                <rect x="378.928" y="360.302" width="23.282" height="9.861" />
                <rect x="408.733" y="360.302" width="23.267" height="9.861" />
                <rect x="438.307" y="360.302" width="23.267" height="9.861" />
                <rect x="468.112" y="360.302" width="23.267" height="9.861" />
                <rect x="498.867" y="360.682" width="23.271" height="9.861" />
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}

function FilePdf({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="588.6px" height="588.6px" viewBox="0 0 588.6 588.6" style={{ "enableBackground": "new 0 0 588.6 588.6" }} xmlSpace="preserve"
        >
            <g>
                <path d="M162.643,247.503c-5.719,0.084-9.384,0.654-11.549,1.213v75.774c2.16,0.554,5.674,0.617,8.849,0.68
           c23.382,0.623,38.908-12.413,38.908-40.666C199.019,259.933,184.886,247.164,162.643,247.503z"/>
                <path d="M359.036,537.78c0.771,0.042,1.551,0.111,2.342,0.111h178.2c20.84,0,37.8-16.96,37.8-37.801V86.994
           c0-20.838-16.96-37.8-37.8-37.8h-178.2c-0.786,0-1.571,0.077-2.342,0.113V0L11.222,46.417v494.564L359.036,588.6V537.78z
            M539.578,516.301h-178.2c-0.797,0-1.571-0.127-2.342-0.242v-162.08c37.131-6.417,88.694-11.274,133.292-0.796l5.278,10.235
           c21.289,40.253,44.028,39.989,52.813,38.006c2.015-0.453,3.101-0.959,3.101-0.959l1.182-0.628c0.4-0.253,0.712-0.575,1.075-0.849
           v101.112C555.777,509.029,548.511,516.301,539.578,516.301z M374.15,334.768c27.906-34.034,50.456-65.807,64.979-87.325
           l0.053,0.119l0.158-0.243c11.401,24.036,30.196,61.214,43.137,86.59l-0.042-0.011l0.443,0.85
           c-36.798-6.286-76.312-3.992-108.812,0.501l0.475-0.554L374.15,334.768z M439.794,175.958c1.118-1.972,2.753-2.494,5.685-2.365
           c0.053,0.005,5.875,0.387,8.047,4.375c1.318,2.375,3.228,9.908-7.045,28.278h-0.011c0,0-1.329,2.21-3.828,6.199
           C436.883,192.222,437.263,180.413,439.794,175.958z M514.107,359.812c10.557,4.061,20.039,9.065,28.339,14.981
           c2.384,3.386,4.588,8.259,4.188,10.558c-0.021,0.111-0.042,0.222-0.105,0.332C543.121,386.311,529.568,386.733,514.107,359.812z
            M361.378,70.798h178.2c8.933,0,16.199,7.27,16.199,16.2v278.614c-0.506-0.723-0.943-1.318-1.202-1.64l-0.686-0.887l-0.896-0.664
           c-13.827-10.094-30.491-17.872-49.581-23.129c-13.61-26.578-41.797-81.974-53.646-107.916c6.861-10.568,10.547-16.728,10.716-17.01
           c10.689-19.056,13.025-33.932,7.114-44.426c-5.358-9.498-16.031-12.351-21.484-12.532c-8.817-0.401-16.337,3.37-20.419,10.552
           c-6.223,10.966-4.936,30.175,3.86,57.056c0.391,1.242,1.018,2.834,1.846,4.838c-14.091,21.312-39.656,58.174-72.367,97.767V71.036
           C359.807,70.922,360.576,70.798,361.378,70.798z M100.311,290.957c-8.224,8.158-20.313,11.718-34.267,11.591
           c-3.069-0.031-5.843-0.221-7.986-0.569v39.698l-22.842-0.633V232.659c7.056-1.469,17.041-2.689,31.292-3.035
           c14.562-0.356,25.109,2.402,32.221,8.277c6.858,5.579,11.485,14.958,11.485,26.087
           C110.214,275.128,106.694,284.573,100.311,290.957z M207.549,331.715c-12.337,10.066-30.881,14.454-53.135,13.832
           c-13.11-0.375-22.307-1.477-28.506-2.5V230.563c9.152-1.743,21.188-2.888,34.035-3.201c21.676-0.53,35.955,3.159,47.271,11.538
           c12.287,8.98,20.084,23.599,20.084,44.745C227.298,306.566,218.977,322.233,207.549,331.715z M245.249,226.194l76.842-1.835v23.106
           l-48.877,0.709v27.891l45.628-0.113v22.884l-45.628-0.316v49.096l-27.973-0.771v-120.65H245.249z"/>
                <path d="M67.745,248.622c-4.77,0.074-7.992,0.614-9.683,1.139v32.777c1.98,0.498,4.438,0.665,7.834,0.665
           c12.551,0.01,20.363-6.837,20.363-18.344C86.26,254.506,79.534,248.432,67.745,248.622z"/>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}
function FilePpt({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="585.918px" height="585.918px" viewBox="0 0 585.918 585.918" style={{ "enableBackground": "new 0 0 585.918 585.918" }}
            xmlSpace="preserve">
            <g>
                <path d="M357.396,535.33c0.776,0.042,1.542,0.109,2.329,0.109h177.39c20.75,0,37.627-16.883,37.627-37.627V86.597
           c0-20.743-16.877-37.628-37.627-37.628h-177.39c-0.781,0-1.553,0.077-2.329,0.113V0L11.176,46.206v492.311l346.22,47.401V535.33z
            M359.726,70.476h177.39c8.893,0,16.125,7.236,16.125,16.126v411.22c0,8.888-7.232,16.127-16.125,16.127h-177.39
           c-0.792,0-1.563-0.127-2.329-0.243V319.212c9.92,37.738,44.168,65.606,85.018,65.606c48.611,0,88.013-39.401,88.013-88.007h-88.013
           v-88.017c-40.854,0-75.103,27.872-85.018,65.612V70.711C358.162,70.599,358.929,70.476,359.726,70.476z M116.427,297.106
           c-8.17,8.126-20.147,11.705-33.982,11.596c-3.055-0.026-5.795-0.21-7.918-0.551v39.438l-22.657-0.571V239.328
           c7.003-1.467,16.906-2.708,31.021-3.081c14.475-0.381,24.903,2.338,31.956,8.166c6.806,5.532,11.401,14.833,11.401,25.888
           C126.253,281.363,122.76,290.754,116.427,297.106z M212.05,297.273c-8.89,8.489-21.942,12.211-36.998,12.1
           c-3.318-0.021-6.302-0.214-8.601-0.572v41.136l-24.651-0.631V237.053c7.622-1.542,18.384-2.838,33.741-3.245
           c15.75-0.409,27.108,2.402,34.796,8.475c7.401,5.774,12.422,15.483,12.422,27.021C222.759,280.848,218.938,290.659,212.05,297.273z
            M324.891,254.173l-34.302,0.56v98.364l-27.779-0.709v-97.209l-32.043,0.527V233.22l94.124-2.441V254.173z"/>
                <path d="M84.122,255.121c-4.738,0.086-7.93,0.63-9.596,1.155v32.57c1.963,0.488,4.396,0.65,7.756,0.64
           c12.438-0.023,20.191-6.833,20.191-18.266C102.474,260.938,95.817,254.916,84.122,255.121z"/>
                <path d="M176.879,253.491c-5.145,0.087-8.622,0.662-10.422,1.205v33.966c2.139,0.512,4.779,0.672,8.428,0.672
           c13.546-0.022,21.984-7.126,21.984-19.066C196.869,259.539,189.604,253.266,176.879,253.491z"/>
                <path d="M454.362,282.922v0.026h88.018c0-24.753-10.269-47.092-26.725-63.08L454.362,282.922z" />
                <path d="M453.428,267.457l62.228-62.231c-17.501-17.512-39.339-26.047-62.29-25.72l0.042,87.93L453.428,267.457z" />
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}
function FileXls({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="585.918px" height="585.918px" viewBox="0 0 585.918 585.918" style={{ "enableBackground": "new 0 0 585.918 585.918" }}
            xmlSpace="preserve">
            <g>
                <path d="M11.176,46.207v492.31l346.22,47.401v-50.583c0.776,0.053,1.542,0.115,2.329,0.115h177.39
           c20.745,0,37.627-16.888,37.627-37.628V86.602c0-20.743-16.882-37.628-37.627-37.628h-177.39c-0.787,0-1.553,0.076-2.329,0.123V0
           L11.176,46.207z M357.396,355.122H435.9v34.939h-78.504V355.122z M357.396,309.432v-34.942H435.9v34.942H357.396z M100.615,350.802
           l-9.659-21.345c-3.913-8.151-6.406-14.161-9.352-20.855l-0.317-0.006c-2.161,6.578-4.78,12.437-7.988,20.279l-8.504,20.168
           l-25.69-1.254l28.866-55.881l-27.856-54.79l25.882-1.063l8.979,20.411c3.068,6.858,5.386,12.427,7.863,18.839h0.305
           c2.496-7.347,4.522-12.501,7.184-19.22l9.014-21.399l28.302-1.173l-30.845,57.819l32.517,60.872L100.615,350.802z M218.837,356.587
           l-75.733-3.7V232.875l26.564-1.098v99.156l49.164,1.565v24.089H218.837z M269.032,360.991c-15.527-0.787-30.541-5.35-37.927-9.691
           l6.013-23.747c8.047,4.241,20.588,8.692,33.753,9.134c14.455,0.483,22.205-4.998,22.205-13.717c0-8.325-6.708-13.182-23.431-19.082
           c-22.501-7.736-36.812-19.602-36.812-38.231c0-21.85,18.869-39.386,51.245-40.772c15.981-0.682,27.992,2.016,36.687,5.294
           l-7.398,24.985c-5.825-2.473-16.067-6.008-29.918-5.654c-13.594,0.354-20.098,6.355-20.098,13.142
           c0,8.349,7.73,11.972,25.735,18.325c25.368,8.73,37.609,21.306,37.609,40.55C326.697,344.44,307.769,362.944,269.032,360.991z
            M359.726,70.476h177.39c8.893,0,16.125,7.236,16.125,16.126v411.22c0,8.893-7.232,16.127-16.125,16.127h-177.39
           c-0.797,0-1.563-0.116-2.329-0.232V395.438h173.024v-166.64H357.396V70.72C358.162,70.604,358.929,70.476,359.726,70.476z
            M525.046,274.489v34.942h-83.765v-34.942H525.046L525.046,274.489z M441.281,269.113v-34.94h83.765v34.94H441.281z
            M525.046,355.122v34.939h-83.765v-34.939H525.046z"/>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}
function FileZip({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="588.601px" height="588.6px" viewBox="0 0 588.601 588.6" style={{ "enableBackground": "new 0 0 588.601 588.6" }}
            xmlSpace="preserve">
            <g>
                <path d="M359.031,537.78c0.781,0.042,1.551,0.111,2.342,0.111h178.2c20.846,0,37.8-16.96,37.8-37.801V86.994
           c0-20.838-16.954-37.8-37.8-37.8h-178.2c-0.786,0-1.561,0.077-2.342,0.113V0L11.228,46.417v494.564L359.031,588.6V537.78z
            M359.031,71.036c0.771-0.113,1.54-0.237,2.342-0.237h178.2c8.933,0,16.2,7.27,16.2,16.2v413.103c0,8.928-7.268,16.2-16.2,16.2
           h-178.2c-0.796,0-1.571-0.127-2.342-0.242V71.036z M145.257,348.828l-82.927-2.115v-13.483l50.055-76.773v-0.681l-45.467,0.751
           v-20.764l77.527-2.012v15.021L94.031,325.83v0.665l51.226,0.807V348.828z M186.97,349.893l-25.784-0.66V233.318l25.784-0.67
           V349.893z M285.544,296.325c-9.608,8.839-23.712,12.709-39.962,12.594c-3.573-0.021-6.803-0.228-9.292-0.602v42.831l-26.568-0.675
           V233.634c8.208-1.619,19.818-2.979,36.395-3.417c16.999-0.448,29.268,2.483,37.568,8.801c8.003,6.012,13.424,16.116,13.424,28.15
           C297.108,279.208,293,289.422,285.544,296.325z"/>
                <path d="M247.554,250.715c-5.545,0.098-9.305,0.696-11.264,1.266v35.363c2.31,0.53,5.168,0.707,9.115,0.696
           c14.628-0.021,23.739-7.425,23.739-19.865C269.144,257.006,261.291,250.472,247.554,250.715z"/>
                <rect x="424.043" y="80.038" width="42.366" height="17.474" />
                <rect x="381.676" y="106.197" width="42.367" height="17.484" />
                <rect x="424.043" y="134.815" width="42.366" height="17.487" />
                <rect x="424.043" y="190.057" width="42.366" height="17.479" />
                <rect x="381.676" y="161.99" width="42.367" height="17.479" />
                <path d="M423.626,378.707c23.172,0,41.939-18.784,41.939-41.966l-7.736-70.284c0-23.172-11.031-41.958-34.203-41.958
           s-34.193,18.786-34.193,41.958l-7.757,70.284C381.676,359.913,400.454,378.707,423.626,378.707z M409.752,301.593h27.774v58.672
           h-27.774V301.593z"/>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}
function FiveStars({ className }) {
    return (
        <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="112.379px" height="19.38px" viewBox="0 0 112.379 19.38" enableBackground="new 0 0 112.379 19.38" xmlSpace="preserve">
            <polygon points="10.19,0 13.337,6.379 20.379,7.403 15.284,12.369 16.487,19.38 10.19,16.069 3.893,19.38 
            5.095,12.369 0,7.403 7.041,6.379 "/>
            <polygon points="33.19,0 36.337,6.379 43.379,7.403 38.284,12.369 39.487,19.38 33.19,16.069 26.893,19.38 
            28.095,12.369 23,7.403 30.041,6.379 "/>
            <polygon points="56.19,0 59.337,6.379 66.379,7.403 61.284,12.369 62.487,19.38 56.19,16.069 49.893,19.38 
            51.095,12.369 46,7.403 53.041,6.379 "/>
            <polygon points="79.19,0 82.337,6.379 89.379,7.403 84.284,12.369 85.487,19.38 79.19,16.069 72.893,19.38 
            74.095,12.369 69,7.403 76.041,6.379 "/>
            <polygon points="102.19,0 105.337,6.379 112.379,7.403 107.284,12.369 108.486,19.38 102.19,16.069 95.893,19.38 
            97.095,12.369 92,7.403 99.041,6.379 "/>
        </svg>
    )
}
function Location({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512 512" style={{ "enableBackground": "new 0 0 512 512" }} xmlSpace="preserve">
            <g>
                <g>
                    <path d="M256,0C156.748,0,76,80.748,76,180c0,33.534,9.289,66.26,26.869,94.652l142.885,230.257
               c2.737,4.411,7.559,7.091,12.745,7.091c0.04,0,0.079,0,0.119,0c5.231-0.041,10.063-2.804,12.75-7.292L410.611,272.22
               C427.221,244.428,436,212.539,436,180C436,80.748,355.252,0,256,0z M384.866,256.818L258.272,468.186l-129.905-209.34
               C113.734,235.214,105.8,207.95,105.8,180c0-82.71,67.49-150.2,150.2-150.2S406.1,97.29,406.1,180
               C406.1,207.121,398.689,233.688,384.866,256.818z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M256,90c-49.626,0-90,40.374-90,90c0,49.309,39.717,90,90,90c50.903,0,90-41.233,90-90C346,130.374,305.626,90,256,90z
                M256,240.2c-33.257,0-60.2-27.033-60.2-60.2c0-33.084,27.116-60.2,60.2-60.2s60.1,27.116,60.1,60.2
               C316.1,212.683,289.784,240.2,256,240.2z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}
function OpenBook({ className }) {
    return (
        <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 297.001 297.001" style={{ "enableBackground": "new 0 0 297.001 297.001" }} xmlSpace="preserve">
            <path d="M287.034,60.873l-20.819-0.001V39.321c0-4.934-3.61-9.126-8.49-9.856c-0.852-0.128-21.134-3.074-45.557,1.37
               c-27.227,4.954-48.941,17.171-63.668,35.64c-14.728-18.469-36.442-30.686-63.668-35.64c-24.424-4.443-44.706-1.498-45.557-1.37
               c-4.88,0.731-8.49,4.923-8.49,9.856v21.551H9.966C4.463,60.872,0,65.335,0,70.839v187.805c0,3.227,1.562,6.254,4.193,8.124
               s6.004,2.35,9.051,1.288c0.748-0.259,75.431-25.747,131.12-0.345c2.628,1.199,5.645,1.199,8.273,0
               c55.533-25.33,130.376,0.088,131.12,0.345c1.068,0.372,2.174,0.555,3.276,0.555c2.043,0,4.065-0.628,5.775-1.842
               c2.631-1.87,4.193-4.897,4.193-8.124V70.84C297,65.336,292.538,60.873,287.034,60.873z M19.933,245.309V80.805h10.852v132.726
               c0,2.896,1.267,5.646,3.458,7.539c2.191,1.893,5.105,2.742,7.969,2.319c0.55-0.08,43.846-6.024,75.478,15.679
               C78.725,232.405,39.727,240.112,19.933,245.309z M138.534,230.08c-13.932-12.588-32.079-21.1-53.702-25.034
               c-10.406-1.894-20.06-2.446-27.78-2.446c-2.292,0-4.414,0.049-6.333,0.126V48.473h-0.001c19.155-0.864,65.752,1.184,87.816,38.587
               V230.08z M158.466,87.061c21.985-37.243,68.655-39.384,87.816-38.563v154.228c-8.383-0.338-20.62-0.136-34.114,2.32
               c-21.623,3.934-39.77,12.445-53.702,25.034V87.061z M179.277,239.074c31.636-21.716,74.955-15.766,75.495-15.686
               c2.871,0.431,5.783-0.413,7.981-2.305c2.198-1.894,3.462-4.65,3.462-7.552V80.806h10.852v164.503
               C257.267,240.11,218.253,232.4,179.277,239.074z"/>
        </svg>
    )
}
function PlayVideo({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="373.008px" height="373.008px" viewBox="0 0 373.008 373.008" style={{ "enableBackground": "new 0 0 373.008 373.008" }}
            xmlSpace="preserve">
            <path d="M61.792,2.588C64.771,0.864,68.105,0,71.444,0c3.33,0,6.663,0.864,9.655,2.588l230.116,167.2
               c5.963,3.445,9.656,9.823,9.656,16.719c0,6.895-3.683,13.272-9.656,16.713L81.099,370.427c-5.972,3.441-13.334,3.441-19.302,0
               c-5.973-3.453-9.66-9.833-9.66-16.724V19.305C52.137,12.413,55.818,6.036,61.792,2.588z"/>
        </svg>
    )
}
function Quote({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="508.044px" height="508.044px" viewBox="0 0 508.044 508.044" style={{ "enableBackground": "new 0 0 508.044 508.044" }}
            xmlSpace="preserve">
            <g>
                <g>
                    <path d="M0.108,352.536c0,66.794,54.144,120.938,120.937,120.938c66.794,0,120.938-54.144,120.938-120.938
               s-54.144-120.937-120.938-120.937c-13.727,0-26.867,2.393-39.168,6.61C109.093,82.118,230.814-18.543,117.979,64.303
               C-7.138,156.17-0.026,348.84,0.114,352.371C0.114,352.426,0.108,352.475,0.108,352.536z"/>
                    <path d="M266.169,352.536c0,66.794,54.144,120.938,120.938,120.938s120.938-54.144,120.938-120.938S453.9,231.599,387.106,231.599
               c-13.728,0-26.867,2.393-39.168,6.61C375.154,82.118,496.875-18.543,384.04,64.303C258.923,156.17,266.034,348.84,266.175,352.371
               C266.175,352.426,266.169,352.475,266.169,352.536z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}

function Quotes({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="512.5px" height="512.5px" viewBox="0 0 512.5 512.5" style={{ "enableBackground": "new 0 0 512.5 512.5" }} xmlSpace="preserve"
        >
            <path d="M112.5,208.25c61.856,0,112,50.145,112,112s-50.144,112-112,112s-112-50.145-112-112l-0.5-16
           c0-123.712,100.288-224,224-224v64c-42.737,0-82.917,16.643-113.137,46.863c-5.817,5.818-11.126,12.008-15.915,18.51
           C100.667,208.723,106.528,208.25,112.5,208.25z M400.5,208.25c61.855,0,112,50.145,112,112s-50.145,112-112,112
           s-112-50.145-112-112l-0.5-16c0-123.712,100.287-224,224-224v64c-42.736,0-82.918,16.643-113.137,46.863
           c-5.818,5.818-11.127,12.008-15.916,18.51C388.666,208.723,394.527,208.25,400.5,208.25z"/>
        </svg>
    )
}
function SearchNew({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512 512" style={{ "enableBackground": "new 0 0 512 512" }} xmlSpace="preserve">
            <g>
                <g>
                    <path d="M225.474,0C101.151,0,0,101.151,0,225.474c0,124.33,101.151,225.474,225.474,225.474
               c124.33,0,225.474-101.144,225.474-225.474C450.948,101.151,349.804,0,225.474,0z M225.474,409.323
               c-101.373,0-183.848-82.475-183.848-183.848S124.101,41.626,225.474,41.626s183.848,82.475,183.848,183.848
               S326.847,409.323,225.474,409.323z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M505.902,476.472L386.574,357.144c-8.131-8.131-21.299-8.131-29.43,0c-8.131,8.124-8.131,21.306,0,29.43l119.328,119.328
               c4.065,4.065,9.387,6.098,14.715,6.098c5.321,0,10.649-2.033,14.715-6.098C514.033,497.778,514.033,484.596,505.902,476.472z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}
function Stars({ className }) {
    return (
        <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="637.035px" height="248.016px" viewBox="0 0 637.035 248.016" enableBackground="new 0 0 637.035 248.016"
            xmlSpace="preserve">
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_1_" x="192.334" width="253.441" height="248.016" />
                    </defs>
                    <clipPath id="SVGID_2_">
                        <use xlinkHref="#SVGID_1_" overflow="visible" />
                    </clipPath>
                    <path clipPath="url(#SVGID_2_)" d="M444.489,93.629c-0.528-1.632-1.94-2.823-3.64-3.066l-81.728-11.89L322.564,4.622
               c-0.744-1.544-2.311-2.514-4.032-2.514c-1.72,0-3.287,0.97-4.059,2.514l-36.551,74.074l-81.728,11.868
               c-1.699,0.243-3.11,1.434-3.64,3.066c-0.529,1.632-0.087,3.442,1.148,4.632l59.14,57.646l-13.964,81.423
               c-0.287,1.666,0.397,3.386,1.787,4.398c1.39,1.028,3.242,1.15,4.765,0.333l73.103-38.425l73.106,38.425
               c0.638,0.353,1.365,0.529,2.092,0.529c0.928,0,1.876-0.287,2.648-0.862c1.391-1.013,2.096-2.732,1.809-4.398l-13.964-81.398
               l59.141-57.67C444.6,97.049,445.041,95.261,444.489,93.629L444.489,93.629z M444.489,93.629"/>
                </g>
            </g>
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_3_" x="456" y="70.856" width="181.035" height="177.16" />
                    </defs>
                    <clipPath id="SVGID_4_">
                        <use xlinkHref="#SVGID_3_" overflow="visible" />
                    </clipPath>
                    <path clipPath="url(#SVGID_4_)" d="M636.117,137.736c-0.378-1.166-1.386-2.017-2.6-2.19l-58.379-8.493l-26.113-52.896
               c-0.532-1.104-1.65-1.796-2.881-1.796c-1.229,0-2.347,0.692-2.898,1.796l-26.11,52.911l-58.379,8.478
               c-1.213,0.173-2.221,1.024-2.6,2.19c-0.378,1.166-0.063,2.458,0.819,3.309l42.245,41.178l-9.975,58.161
               c-0.205,1.189,0.283,2.417,1.276,3.142c0.992,0.733,2.314,0.822,3.403,0.237l52.218-27.447l52.221,27.447
               c0.455,0.252,0.975,0.378,1.494,0.378c0.663,0,1.341-0.205,1.892-0.615c0.994-0.725,1.497-1.952,1.292-3.142l-9.975-58.145
               l42.246-41.194C636.195,140.18,636.511,138.902,636.117,137.736L636.117,137.736z M636.117,137.736"/>
                </g>
            </g>
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_5_" y="70.856" width="181.035" height="177.16" />
                    </defs>
                    <clipPath id="SVGID_6_">
                        <use xlinkHref="#SVGID_5_" overflow="visible" />
                    </clipPath>
                    <path clipPath="url(#SVGID_6_)" d="M180.117,137.736c-0.378-1.166-1.386-2.017-2.6-2.19l-58.379-8.493L93.025,74.158
               c-0.532-1.104-1.65-1.796-2.881-1.796c-1.229,0-2.347,0.692-2.898,1.796l-26.11,52.911l-58.379,8.478
               c-1.213,0.173-2.221,1.024-2.6,2.19c-0.378,1.166-0.063,2.458,0.819,3.309l42.245,41.178l-9.975,58.161
               c-0.205,1.189,0.283,2.417,1.276,3.142c0.992,0.733,2.314,0.822,3.403,0.237l52.218-27.447l52.221,27.447
               c0.455,0.252,0.975,0.378,1.494,0.378c0.663,0,1.341-0.205,1.892-0.615c0.994-0.725,1.497-1.952,1.292-3.142l-9.975-58.145
               l42.246-41.194C180.195,140.18,180.511,138.902,180.117,137.736L180.117,137.736z M180.117,137.736"/>
                </g>
            </g>
        </svg>
    )
}



export { Arrow_r, Certificate, Check, Checked, FileDoc, FileMp3, FileMp4, FilePdf, FilePpt, FileXls, FileZip, FiveStars, Location, OpenBook, PlayVideo, Quote, Quotes, SearchNew, Stars } 