import React from 'react'

function Badoo({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512 512" style={{"enableBackground":"new 0 0 512 512"}} xmlSpace="preserve">
            <g>
                <g>
                    <path d="M404.359,153.835c-39.342-39.343-92.731-61.457-148.37-61.454c-56.039,0.003-108.722,21.827-148.347,61.452
			c-1.142,1.142-2.265,2.297-3.376,3.46V15c0-8.284-6.716-15-15-15H61.19c-8.284,0-15,6.716-15,15v285.486
			c0,55.578,21.294,109.299,60.294,148.896C146.24,489.747,199.409,512,255.998,512c55.846,0,109.51-22.34,148.867-61.961
			c39.306-39.569,60.944-92.045,60.944-147.848C465.81,246.15,443.987,193.463,404.359,153.835z M256,453.924
			c-83.666,0-151.734-68.067-151.734-151.733c0-83.667,68.068-151.734,151.734-151.734s151.734,68.068,151.734,151.734
			S339.667,453.924,256,453.924z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}


function BaiduLogo({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="96.803px" height="96.803px" viewBox="0 0 96.803 96.803" style={{"enableBackground":"new 0 0 96.803 96.803"}}
            xmlSpace="preserve">
            <g>
                <g>
                    <path d="M36.962,29.912c5.808,0,10.502-6.691,10.502-14.959C47.464,6.682,42.769,0,36.962,0c-5.811,0-10.518,6.682-10.518,14.953
               C26.444,23.22,31.15,29.912,36.962,29.912z"/>
                    <path d="M62.001,30.896c7.775,1.018,12.757-7.275,13.752-13.561c1.02-6.275-4.005-13.576-9.497-14.827
               c-5.515-1.27-12.4,7.561-13.021,13.321C52.48,22.875,54.235,29.902,62.001,30.896z"/>
                    <path d="M17.422,51.014c10.521-2.261,9.085-14.85,8.777-17.6c-0.518-4.242-5.505-11.65-12.271-11.059
               c-8.519,0.759-9.762,13.063-9.762,13.063C3.011,41.118,6.918,53.279,17.422,51.014z"/>
                    <path d="M81.034,67.841c0,0-12.02-9.297-19.033-19.351c-9.523-14.818-23.039-8.783-27.556-1.257
               c-4.505,7.544-11.507,12.311-12.51,13.576c-1.011,1.239-14.516,8.536-11.519,21.861C13.419,95.984,23.95,95.732,23.95,95.732
               s7.762,0.758,16.763-1.254c9.02-2.01,16.78,0.491,16.78,0.491s21.041,7.056,26.791-6.521
               C90.039,74.886,81.034,67.841,81.034,67.841z"/>
                    <path d="M92.791,41.455c0-3.006-2.489-12.066-11.756-12.066c-9.286,0-10.513,8.551-10.513,14.591c0,5.768,0.48,13.81,12.013,13.56
               C94.054,57.283,92.791,44.48,92.791,41.455z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}


function BigSkypeLogo({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 293.828 293.828" style={{"enableBackground":"new 0 0 293.828 293.828"}} xmlSpace="preserve">
            <path d="M284.642,174.604c1.668-8.685,2.513-17.513,2.513-26.267c0-76.523-62.273-138.779-138.817-138.779
       c-6.598,0-13.273,0.478-19.87,1.422C115.764,3.792,101.383,0,86.758,0C39.975,0,1.914,38.066,1.914,84.856
       c0,13.689,3.356,27.279,9.721,39.422c-1.388,7.92-2.091,16.004-2.091,24.058c0,76.531,62.263,138.794,138.793,138.794
       c7.368,0,14.725-0.578,21.893-1.719c11.426,5.511,24.127,8.416,36.843,8.416c46.782,0,84.842-38.062,84.842-84.847
       C291.912,196.992,289.467,185.436,284.642,174.604z M144.059,127.973c1.886,0.611,3.814,1.236,5.779,1.887
       c2.589,0.856,5.299,1.696,7.92,2.508c10.849,3.361,22.067,6.837,31.239,13.356c12.093,8.596,18.225,20.765,18.225,36.167
       c0,14.151-6.257,26.514-17.619,34.809c-10.153,7.413-24.142,11.331-40.453,11.331c-48.718,0-60.419-28.631-62.845-37.405
       c-2.207-7.985,2.477-16.248,10.461-18.455c7.982-2.204,16.247,2.477,18.455,10.461c2.705,9.787,15.071,15.399,33.929,15.399
       c8.44,0,28.072-1.572,28.072-16.14c0-10.664-5.698-13.851-28.343-20.868c-2.755-0.854-5.604-1.736-8.467-2.684
       c-1.907-0.631-3.777-1.237-5.605-1.83c-11.4-3.696-22.168-7.187-30.633-13.165c-11.199-7.909-16.878-19.123-16.878-33.329
       c0-21.975,21.084-44.221,61.38-44.221c34.518,0,50.294,15.104,57.45,27.773c4.074,7.213,1.529,16.363-5.684,20.438
       c-7.213,4.074-16.362,1.53-20.438-5.684c-1.75-3.099-7.076-12.527-31.329-12.527c-20.475,0-31.38,8.259-31.38,14.221
       C117.296,117.657,119.603,120.044,144.059,127.973z"/>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}

function Facebook({ className }) {
    return (
        <svg className={className} enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z" /></svg>
    )
}

function FourSquare({ className }) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"  x="0px" y="0px" viewBox="0 0 512 512" style={{"enableBackground":"new 0 0 512 512"}} xmlSpace="preserve" width="512" height="512">
            <g>
                <path d="M241.002,150h168.3l26.4-132.001c0.901-4.499-0.3-9-2.999-12.599c-3.001-3.3-7.2-5.4-11.7-5.4h-330   c-8.401,0-15,6.599-15,15v482c0,6.299,3.9,11.999,9.901,14.099c1.8,0.601,3.3,0.901,5.099,0.901c4.501,0,8.699-1.8,11.4-5.4   L247.902,330h113.101c7.2,0,13.2-5.101,14.7-12.001L403.301,180H241.002c-8.401,0-15-6.601-15-15   C226.002,156.599,232.601,150,241.002,150z" />
            </g>















        </svg>
    )
}

function GooglePlus({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512 512" style={{"enableBackground":"new 0 0 512 512"}} xmlSpace="preserve">
            <g>
                <g>
                    <polygon points="448,224 448,160 416,160 416,224 352,224 352,256 416,256 416,320 448,320 448,256 512,256 512,224 		" />
                </g>
            </g>
            <g>
                <g>
                    <path d="M160,224v64h90.528c-13.216,37.248-48.8,64-90.528,64c-52.928,0-96-43.072-96-96c0-52.928,43.072-96,96-96
               c22.944,0,45.024,8.224,62.176,23.168l42.048-48.256C235.424,109.824,198.432,96,160,96C71.776,96,0,167.776,0,256
               s71.776,160,160,160s160-71.776,160-160v-32H160z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}

function Instagram({ className }) {
    return (
        <svg className={className} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m437 0h-362c-41.351562 0-75 33.648438-75 75v362c0 41.351562 33.648438 75 75 75h362c41.351562 0 75-33.648438 75-75v-362c0-41.351562-33.648438-75-75-75zm-180 390c-74.441406 0-135-60.558594-135-135s60.558594-135 135-135 135 60.558594 135 135-60.558594 135-135 135zm150-240c-24.8125 0-45-20.1875-45-45s20.1875-45 45-45 45 20.1875 45 45-20.1875 45-45 45zm0 0" /><path d="m407 90c-8.277344 0-15 6.722656-15 15s6.722656 15 15 15 15-6.722656 15-15-6.722656-15-15-15zm0 0" /><path d="m257 150c-57.890625 0-105 47.109375-105 105s47.109375 105 105 105 105-47.109375 105-105-47.109375-105-105-105zm0 0" /></svg>
    )
}

function Line({ className }) {
    return (
        <svg className={className} enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 .5c-6.615 0-12 4.398-12 9.803 0 4.841 4.27 8.897 10.035 9.668.391.083.923.26 1.058.594.12.303.079.771.038 1.087l-.164 1.026c-.045.303-.24 1.193 1.049.649 1.291-.542 6.916-4.104 9.436-7.019 1.724-1.9 2.548-3.847 2.548-6.005 0-5.405-5.385-9.803-12-9.803zm-4.697 13.017h-2.386c-.345 0-.63-.287-.63-.633v-4.801c0-.347.285-.634.63-.634.348 0 .63.287.63.634v4.167h1.756c.348 0 .629.285.629.634 0 .346-.282.633-.629.633zm2.466-.633c0 .346-.282.633-.631.633-.345 0-.627-.287-.627-.633v-4.801c0-.347.282-.634.63-.634.346 0 .628.287.628.634zm5.741 0c0 .272-.174.513-.432.6-.064.021-.133.031-.199.031-.211 0-.391-.091-.51-.252l-2.443-3.338v2.958c0 .346-.279.633-.631.633-.346 0-.626-.287-.626-.633v-4.8c0-.272.173-.513.43-.599.06-.023.136-.033.194-.033.195 0 .375.105.495.256l2.462 3.351v-2.975c0-.347.282-.634.63-.634.345 0 .63.287.63.634zm3.855-3.035c.349 0 .63.287.63.635 0 .347-.281.634-.63.634h-1.755v1.132h1.755c.349 0 .63.285.63.634 0 .346-.281.633-.63.633h-2.386c-.345 0-.627-.287-.627-.633v-4.801c0-.347.282-.634.63-.634h2.386c.346 0 .627.287.627.634 0 .351-.281.634-.63.634h-1.755v1.132z" /></svg>
    )
}

function Linkedin({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512 512" style={{"enableBackground":"new 0 0 512 512"}} xmlSpace="preserve">
            <g>
                <g>
                    <rect y="160" width="114.496" height="352" />
                </g>
            </g>
            <g>
                <g>
                    <path d="M426.368,164.128c-1.216-0.384-2.368-0.8-3.648-1.152c-1.536-0.352-3.072-0.64-4.64-0.896
               c-6.08-1.216-12.736-2.08-20.544-2.08c-66.752,0-109.088,48.544-123.04,67.296V160H160v352h114.496V320
               c0,0,86.528-120.512,123.04-32c0,79.008,0,224,0,224H512V274.464C512,221.28,475.552,176.96,426.368,164.128z"/>
                </g>
            </g>
            <g>
                <g>
                    <circle cx="56" cy="56" r="56" />
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}

function NightClub({ className }) {
    return (
        <svg className={className} height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m106 76c-8.277344 0-15 6.722656-15 15v45h30v-45c0-8.277344-6.722656-15-15-15zm0 0" /><path d="m406 76c-8.277344 0-15 6.722656-15 15v45h30v-45c0-8.277344-6.722656-15-15-15zm0 0" /><path d="m0 241h512v-241h-512zm361-150c0-24.8125 20.1875-45 45-45s45 20.1875 45 45v75h-90zm-180 75h30v-85.8125l120-40.003906v125.816406h-60v-30h30v-54.183594l-60 19.992188v94.191406h-60zm-120-75c0-24.8125 20.1875-45 45-45s45 20.1875 45 45v75h-90zm0 0" /><path d="m301 437c0-19.53125-12.578125-36.023438-30-42.238281v117.238281h30zm0 0" /><path d="m241 394.761719c-17.421875 6.214843-30 22.707031-30 42.238281v75h30zm0 0" /><path d="m391 271h-270v151h30v90h30v-75c0-41.351562 33.648438-75 75-75s75 33.648438 75 75v75h30v-90h30zm-90 60h-90v-30h90zm0 0" /><path d="m121 452h-30v-181h-61v181h-30v60h121zm0 0" /><path d="m481 271h-60v181h-30v60h121v-60h-31zm0 0" /></svg>
    )
}

function Qq({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="98.802px" height="98.803px" viewBox="0 0 98.802 98.803" style={{"enableBackground":"new 0 0 98.802 98.803"}}
            xmlSpace="preserve">
            <g>
                <g>
                    <path d="M95.568,57.454c-1.74-4.367-3.976-8.49-6.733-12.316c-0.295-0.408-0.602-0.729-0.329-1.404
               c1.326-3.281,0.896-6.463-0.798-9.515c-0.763-1.376-1.668-2.684-1.755-4.342c-0.127-2.393-0.734-4.692-1.356-6.994
               c-2.17-8.031-6.494-14.449-13.937-18.479c-4.224-2.287-8.764-3.589-13.545-4.115C52.19-0.253,47.321-0.04,42.472,0.987
               c-8.02,1.701-13.92,6.429-18.489,12.984c-3.001,4.308-5.137,8.993-5.776,14.3c-0.123,1.021,0.25,2.146-0.41,3.085
               c-0.573,0.812-0.9,1.724-1.063,2.675c-0.245,1.425-0.573,2.778-1.304,4.073c-0.888,1.57-1.127,3.374-0.764,5.138
               c0.157,0.758-0.005,1.153-0.531,1.548c-3.109,2.327-5.68,5.131-7.84,8.373c-3.077,4.616-4.894,9.619-5.189,15.16
               c-0.119,2.225,0.15,4.398,0.933,6.505c0.379,1.02,0.88,1.498,2.084,1.148c1.013-0.293,1.878-0.748,2.645-1.423
               c1.6-1.404,2.905-3.04,3.769-5.004c0.1-0.228,0.074-0.579,0.439-0.561c0.332,0.016,0.363,0.306,0.42,0.573
               c0.518,2.398,1.633,4.556,2.829,6.659c1.276,2.247,3.105,4.056,5.017,5.75c0.667,0.592,1.614,0.868,1.987,1.871
               c-1.38-0.002-2.656,0.194-3.863,0.609c-2.062,0.711-3.895,1.764-4.372,4.145c-0.456,2.275-0.613,4.522,1.467,6.206
               c0.823,0.666,1.734,1.195,2.716,1.614c3.463,1.477,7.142,1.956,10.837,2.194c4.568,0.294,9.156,0.404,13.635-0.838
               c2.596-0.722,4.999-1.891,7.251-3.366c0.213-0.14,0.354-0.46,0.658-0.372c1.79,0.518,3.677-0.02,5.49,0.687
               c2.91,1.136,5.917,2.001,9.02,2.501c4.605,0.744,9.227,1.093,13.874,0.502c3.149-0.401,6.235-1.094,8.993-2.768
               c3.546-2.153,3.654-5.891,0.326-8.31c-1.64-1.192-3.38-2.186-5.205-3.05c-0.472-0.223-0.991-0.376-1.364-0.893
               c3.672-3.374,5.523-7.843,7.374-12.409c1.054,1.952,2.08,3.805,3.441,5.433c1.449,1.731,2.711,1.69,4.132-0.04
               c0.566-0.69,0.981-1.451,1.239-2.315C98.51,67.896,97.619,62.604,95.568,57.454z M55.018,22.695
               c-0.062-2.094,0.374-4.126,1.512-5.984c2.2-3.594,5.927-3.671,8.122-0.082c1.899,3.109,1.954,7.003,0.982,10.438
               c-0.47,1.66-1.153,3.151-2.801,3.994c-2.239,1.145-4.307,0.692-5.812-1.331C55.482,27.662,54.927,25.299,55.018,22.695z
                M40.416,15.943c2.095-2.708,5.158-2.722,7.237-0.017c1.574,2.05,2.052,4.435,2.091,7.159c-0.076,2.407-0.588,4.892-2.398,6.899
               c-2.086,2.315-4.877,2.194-6.817-0.231C37.729,26.254,37.674,19.486,40.416,15.943z M31.089,39.146
               c3.005-2.065,6.387-3.264,9.902-4.027c7.729-1.682,15.478-1.892,23.2,0.086c3.134,0.803,6.169,1.89,8.897,3.668
               c1.692,1.104,1.673,1.513-0.021,2.552c-1.81,1.109-3.694,2.027-6.063,2.02c0.854-0.947,1.935-1.479,2.597-2.923
               c-11.517,7.921-22.792,8.559-34.122,0.353c0.501,0.808,1.002,1.614,1.618,2.606c-2.195-0.55-4.16-1.071-5.952-2.04
               C29.729,40.672,29.748,40.068,31.089,39.146z M45.498,94.378c-1.388,1.356-3.231,1.805-4.997,2.193
               c-6.68,1.475-13.408,1.794-20.09,0.042c-2.074-0.543-4.159-1.262-5.741-2.864c-1.172-1.185-1.151-2.205,0.02-3.421
               c0.726-0.755,1.572-1.359,2.358-2.14c-0.603,0.107-1.211,0.196-1.808,0.337c-0.297,0.069-0.646,0.303-0.824-0.039
               c-0.122-0.235-0.103-0.648,0.025-0.892c0.29-0.544,0.689-1.041,1.236-1.357c0.763-0.443,1.53-0.892,2.332-1.255
               c1.908-0.865,3.584-0.936,5.472,0.514c3.637,2.791,7.861,4.532,12.245,5.885c3.109,0.96,6.28,1.586,9.487,2.072
               c0.244,0.038,0.583-0.093,0.711,0.2C46.091,94.035,45.705,94.175,45.498,94.378z M81.455,84.153
               c1.248,0.611,2.564,1.141,4.022,2.31c-1.181,0.092-2.198,0.127-3.067,0.681c-0.171,0.106-0.416,0.311-0.405,0.454
               c0.028,0.373,0.373,0.263,0.621,0.262c1.151-0.001,2.304-0.059,3.452,0.001c2.125,0.109,3.197,1.731,2.403,3.692
               c-1.039,2.568-3.396,3.5-5.763,4.248c-7.481,2.366-14.902,1.625-22.27-0.625c-0.812-0.249-1.776-0.215-2.169-1.324
               c7.716-1.221,14.533-4.239,20.361-9.354C79.717,83.552,80.247,83.559,81.455,84.153z M84.223,68.128
               c-0.26,4.43-1.97,8.329-4.652,11.788c-5.173,6.673-11.993,10.796-20.188,12.656c-3.104,0.706-6.256,0.349-9.376,0.045
               c-4.791-0.465-9.515-1.327-13.972-3.219c-2.77-1.177-5.435-2.546-7.813-4.473c-4.629-3.753-8.246-8.165-9.446-14.146
               c-1.086-5.412-0.645-10.715,1.674-15.791c0.164-0.358,0.373-0.696,0.543-1.052c0.414-0.856,0.823-1.223,1.793-0.484
               c1.042,0.791,2.265,1.348,3.431,1.966c0.447,0.237,0.563,0.432,0.49,1.003c-0.504,4.039-0.938,8.08-0.483,12.171
               c0.272,2.438,1.731,3.976,3.747,4.851c2.783,1.207,5.785,1.057,8.735,0.577c1.204-0.195,2.569-1.76,2.516-3.548l-0.192-8.102
               l-0.069-1.684c3.209,0.899,6.507,1.185,9.782,1.263c7.792,0.186,15.094-1.702,22.083-5.021c2.072-0.983,4.073-2.088,5.977-3.359
               c0.473-0.315,0.655-0.347,1.007,0.171C82.755,58.09,84.538,62.793,84.223,68.128z M36.888,64.798l-0.091-3.047
               c0.059-0.565-0.266-1.596,0.643-1.748c1.124-0.188,2.169,0.613,2.277,1.747c0.269,2.827,0.451,5.684,0.349,8.552
               c-0.049,1.381-0.726,2.211-2.281,2.291c-2.221,0.117-4.431,0.192-6.611-0.293c-3.059-0.683-4.14-2.181-4.231-5.647
               c-0.087-3.265,0.691-6.405,1.279-9.576c0.094-0.508,0.288-0.49,0.706-0.312c1.94,0.832,3.841,1.771,5.895,2.308
               c0.619,0.161,0.524,0.587,0.541,1.025c0.076,2.042,0.341,4.055,1.032,5.99c0.113,0.316,0.279,0.617,0.525,1.172L36.888,64.798z
                M87.863,41.959c-0.713,3.928-2.98,6.794-6.25,8.828c-6.996,4.354-14.417,7.735-22.591,9.235
               c-4.74,0.869-9.478,0.834-14.262,0.222c-5.7-0.728-11.113-2.364-16.314-4.708c-4.34-1.956-8.464-4.3-11.461-8.165
               c-2.191-2.824-2.488-5.776-0.475-8.403c0.613,3.759,2.714,6.468,5.648,8.647c-1.113-1.906-2.246-3.8-3.333-5.72
               c-1.16-2.046-1.057-4.28-0.949-6.513c0.127-0.013,0.255-0.054,0.276-0.023c3.985,5.908,9.673,9.502,16.248,11.818
               c8.313,2.933,16.929,3.846,25.633,2.862c8.854-1,16.799-4.403,23.481-10.46c0.426-0.385,0.882-0.734,1.218-1.014
               c-1.527,6.333-6.051,10.371-11.515,13.634c7.514-2.716,11.403-8.663,14.022-15.749C88.027,37.638,88.234,39.91,87.863,41.959z"/>
                    <path d="M57.824,24.385c0.522,0.103,0.59-0.406,0.691-0.783c0.194-0.719,0.302-1.658,1.196-1.672
               c0.82-0.011,0.854,0.921,0.957,1.529c0.082,0.484,0.37,0.993,0.901,0.919c0.674-0.094,0.597-3.508-1.134-4.097
               c-1.595-0.601-3.162,0.939-3.122,3.106C57.321,23.776,57.325,24.288,57.824,24.385z"/>
                    <path d="M46.776,26.242c0.833,0.062,1.306-0.495,1.617-1.142c0.776-1.614,0.754-3.243-0.183-4.788
               c-0.681-1.121-1.811-1.173-2.591-0.158c-0.619,0.805-0.779,1.753-0.757,2.742c0.015,0.705,0.073,1.401,0.379,2.056
               C45.552,25.621,45.975,26.179,46.776,26.242z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}

function QzoneLogo({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="97.683px" height="97.683px" viewBox="0 0 97.683 97.683" style={{"enableBackground":"new 0 0 97.683 97.683"}}
            xmlSpace="preserve">
            <g>
                <path d="M97.578,37.764c-0.249-0.754-0.93-1.291-1.732-1.365l-31.396-2.91L50.817,3.469c-0.312-0.749-1.052-1.237-1.873-1.237
           c-0.82,0-1.561,0.489-1.871,1.237L33.021,32.831L1.833,36.399c-0.809,0.075-1.493,0.619-1.738,1.381
           c-0.246,0.762-0.004,1.595,0.613,2.114l23.047,21.137L18.083,92.97c-0.183,0.781,0.126,1.592,0.784,2.062
           c0.354,0.252,0.771,0.378,1.188,0.378c0.361,0,0.725-0.096,1.047-0.288l28.05-16.117l27.632,16.326
           c0.688,0.202,1.558,0.17,2.214-0.285c0.657-0.455,0.978-1.25,0.815-2.025l-4.175-23.645c1.103-0.512,4.345-1.941,6.05-3.902
           c-2.78,1.099-3.826,1.389-6.607,1.914v-0.006c-18.563,3.566-47.045,0.541-48.004,0.494L58.316,45
           c-10.575-1.941-35.127-2.716-36.642-2.763c-0.197,0.032-0.232,0.034-0.079-0.002c0,0,0.028,0,0.079,0.002
           c1.275-0.206,33.333-5.459,51.921-0.21L42.134,64.087c0,0,24.257,2.358,32.813,1.367l-0.573-4.424l22.83-21.158
           C97.6,39.347,97.829,38.518,97.578,37.764z"/>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}

function Reddit({ className }) {
    return (
        <svg className={className} enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z" /></svg>
    )
}

function Renren({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="556.748px" height="556.748px" viewBox="0 0 556.748 556.748" style={{"enableBackground":"new 0 0 556.748 556.748"}}
            xmlSpace="preserve">
            <g>
                <g>
                    <path d="M443.297,52.194C408.014,26.355,368.748,9.789,325.498,2.536v175.797c0,60.9,15.281,116.415,45.852,166.549
               c30.562,50.153,70.502,87.18,119.793,111.103c43.734-51.946,65.605-111.757,65.605-179.42c0-45.196-10.092-87.418-30.262-126.696
               C506.309,110.628,478.578,78.063,443.297,52.194z"/>
                    <path d="M185.399,344.889c30.569-50.135,45.851-105.655,45.851-166.549V2.536c-43.256,7.252-82.522,23.813-117.804,49.658
               c-35.288,25.87-63.012,58.434-83.189,97.682C10.086,189.153,0,231.375,0,276.572c0,67.668,21.867,127.473,65.606,179.419
               C114.903,432.068,154.83,395.043,185.399,344.889z"/>
                    <path d="M325.314,443.66c-23.562-29.964-39.572-62.345-48.029-97.144c-8.219,34.805-24.101,67.186-47.663,97.144
               c-23.562,29.976-52.014,55.459-85.362,76.487c41.322,22.712,85.662,34.064,133.024,34.064c47.602,0,92.068-11.353,133.391-34.064
               C377.328,499.125,348.877,473.636,325.314,443.66z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}

function SinaWeibo({ className }) {
    return (
        <svg className={className} enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m23.977 8.171c.103 1.315-.123 2.891-1.006 2.969-1.442.127-.69-1.435-.671-2.375.053-2.725-2.312-4.618-4.629-4.618-.656 0-2.181.438-1.946-.924.106-.6.623-.592 1.14-.66 3.897-.51 6.838 2.125 7.112 5.608z" /><path d="m17.604 11.337c1.675.901 3.687 1.35 3.354 4.024-.614 4.929-11.054 8.24-17.575 4.618-1.788-.994-3.639-2.448-3.354-5.344.246-2.493 1.95-4.424 3.623-6.069 1.596-1.57 3.278-2.798 5.567-3.364 2.484-.614 3.218 1.422 2.549 3.43 1.439-.095 4.488-1.674 5.836-.132.594.68.367 1.897 0 2.837zm-1.811 6.333c.537-.598 1.076-1.51 1.073-2.572-.01-4.395-7.635-5.376-11.806-2.969-4.747 2.74-2.743 7.664 3.086 8.048 3.088.204 6.179-.868 7.647-2.507z" /><path d="m19.952 10.084c-.977.064-.612-.658-.671-1.517-.037-.529-.483-1.14-.872-1.32-.763-.352-1.945.256-1.945-.791 0-.778.686-.697 1.074-.726 3.555-.266 3.775 4.265 2.414 4.354z" /><path d="m12.842 14.768c1.429 4.248-6.017 6.223-7.379 2.573-.911-2.441 1.277-4.383 3.555-4.618 1.945-.201 3.395.775 3.824 2.045zm-3.422.726c.303.516 1 .145.939-.264-.073-.473-.945-.403-.939.264zm-1.207 2.243c1.252-.293 1.42-2.612-.47-2.177-1.587.364-1.28 2.586.47 2.177z" /></svg>
    )
}

function SnapChat({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512.016 512.016" style={{"enableBackground":"new 0 0 512.016 512.016"}} xmlSpace="preserve">
            <g>
                <g>
                    <path d="M500.459,375.368c-64.521-10.633-93.918-75.887-97.058-83.294c-0.06-0.145-0.307-0.666-0.375-0.819
               c-3.234-6.571-4.036-11.904-2.347-15.838c3.388-8.013,17.741-12.553,26.931-15.462c2.586-0.836,5.009-1.604,6.938-2.372
               c18.586-7.339,27.913-16.717,27.716-27.895c-0.179-8.866-7.134-17.007-17.434-20.651c-3.55-1.485-7.774-2.295-11.887-2.295
               c-2.842,0-7.066,0.401-11.102,2.287c-7.868,3.678-14.865,5.658-20.156,5.888c-2.355-0.094-4.139-0.486-5.427-0.922
               c0.162-2.79,0.35-5.658,0.529-8.585l0.094-1.493c2.193-34.807,4.915-78.123-6.673-104.081
               c-34.27-76.834-106.999-82.807-128.478-82.807l-10.018,0.094c-21.436,0-94.029,5.965-128.265,82.756
               c-11.614,26.018-8.866,69.316-6.664,104.115c0.213,3.422,0.427,6.758,0.614,10.01c-1.468,0.503-3.584,0.947-6.46,0.947
               c-6.161,0-13.542-1.997-21.931-5.922c-12.126-5.683-34.295,1.911-37.291,17.647c-1.63,8.516,1.801,20.796,27.383,30.908
               c1.988,0.785,4.489,1.587,7.561,2.56c8.576,2.722,22.929,7.27,26.325,15.266c1.681,3.951,0.879,9.284-2.662,16.512
               c-1.263,2.944-31.65,72.124-98.765,83.174c-6.963,1.143-11.93,7.322-11.537,14.353c0.111,1.954,0.563,3.917,1.399,5.897
               c5.641,13.193,27.119,22.349,67.55,28.766c0.887,2.295,1.92,7.006,2.509,9.737c0.853,3.9,1.749,7.927,2.97,12.1
               c1.229,4.224,4.881,11.307,15.445,11.307c3.575,0,7.714-0.811,12.211-1.681c6.468-1.271,15.309-2.995,26.274-2.995
               c6.084,0,12.416,0.546,18.825,1.604c12.092,2.005,22.699,9.506,35.004,18.202c18.116,12.809,34.586,22.605,67.524,22.605
               c0.87,0,1.732-0.026,2.577-0.085c1.22,0.06,2.449,0.085,3.695,0.085c28.851,0,54.246-7.62,75.494-22.63
               c11.742-8.311,22.835-16.162,34.935-18.176c6.426-1.058,12.766-1.604,18.85-1.604c10.513,0,18.901,1.348,26.385,2.816
               c5.06,0.998,9.02,1.476,12.672,1.476c7.373,0,12.8-4.053,14.874-11.127c1.195-4.113,2.091-8.021,2.961-12.015
               c0.461-2.125,1.57-7.211,2.509-9.66c39.851-6.34,60.203-15.138,65.835-28.297c0.845-1.894,1.34-3.9,1.476-6.033
               C512.372,382.707,507.422,376.529,500.459,375.368z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}

function Tagged({ className }) {
    return (
        <svg className={className} enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12.379 0c.53.114.989.411 1.452.677 8.433 4.84 8.282 4.655 8.488 5.128.088.312-.19.611-.507.564-5.068.004-10.135-.003-15.204.003-.931.008-1.766.776-1.884 1.69-.175 1.084.735 2.176 1.833 2.224 1.007.02 2.017-.005 3.024.008.694-.01 1.313.623 1.296 1.313.042.448.169.05-4.172 8.357-.122.223-.218.468-.391.66-.215.206-.561.173-.794.015-1.078-.626-2.166-1.235-3.24-1.868-.587-.331-.982-.943-1.031-1.615v-10.398c.089-.659.499-1.241 1.071-1.574 11.323-6.525 8.409-4.933 10.059-5.184z" /><path d="m14.966 12.634c.723-1.413 2.275-2.364 3.87-2.343 1.304.005 2.608 0 3.914.003 0 7.096.109 7.222-.328 7.837-.391.555-.237.377-8.818 5.303-1.923 1.113-2.181.478-4.034-.466 1.784-3.454 3.603-6.888 5.396-10.334z" /></svg>
    )

}

function TaringaLogo({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="97.75px" height="97.75px" viewBox="0 0 97.75 97.75" style={{"enableBackground":"new 0 0 97.75 97.75"}} xmlSpace="preserve"
        >
            <g>
                <g>
                    <path d="M48.875,0C21.883,0,0,21.882,0,48.875S21.883,97.75,48.875,97.75S97.75,75.868,97.75,48.875S75.867,0,48.875,0z
                M17.427,46.116h-3.716v10.106H9.957V46.116H6.242v-3.654h11.185V46.116z M29.998,56.225h-4.13v-4.336h-3.274v4.336h-4.128v-9.478
               c0,0-0.108-4.478,3.754-4.478c3.861,0,0.796,0,4.011,0c3.768,0,3.768,4.478,3.768,4.478L29.998,56.225L29.998,56.225z
                M38.165,56.225l-1.587-3.379h-1.157v3.379h-3.754V42.462c0,0,5.075,0,7.52,0s2.86,2.549,2.86,2.549s0,0.064,0,3.048
               s-1.768,3.555-1.768,3.555l1.946,4.609h-4.06V56.225z M47.31,56.225h-3.754V42.462h3.754V56.225z M56.748,56.225l-4.089-7.209
               v7.209H48.93V42.462h4.011l4.103,7.218v-7.218h3.715v13.744l0.015,0.019H56.748z M72.875,53.684c0,0-0.284,2.541-4.346,2.541
               c-0.348,0-0.596,0-0.771,0c0,0-0.09,0-0.362,0c-0.176,0-0.429,0-0.783,0c-4.256,0-4.801-2.523-4.801-2.523v-7.584
               c0,0-0.223-3.654,3.766-3.654s6.471,0,6.471,0l0.004,3.658h0.004v0.054c0-0.017-0.002-0.037-0.004-0.055l-6.486-0.004v6.841h3.521
               v-2.339h-2.024v-2.55h5.812V53.684z M85.732,56.225h-4.129v-4.336h-3.275v4.336h-4.127v-9.478c0,0-0.108-4.478,3.754-4.478
               c3.861,0,0.796,0,4.011,0c3.769,0,3.769,4.478,3.769,4.478v9.478H85.732z M91.507,56.225h-3.726v-3.892h3.726V56.225z
                M87.782,51.546v-10.02h3.726v10.02H87.782z"/>
                    <rect x="78.328" y="46.31" width="3.275" height="2.842" />
                    <rect x="22.594" y="46.31" width="3.274" height="2.842" />
                    <rect x="35.275" y="45.971" width="3.113" height="2.919" />
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )

}

function Telegram1({ className }) {
    return (
        <svg className={className} enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m9.417 15.181-.397 5.584c.568 0 .814-.244 1.109-.537l2.663-2.545 5.518 4.041c1.012.564 1.725.267 1.998-.931l3.622-16.972.001-.001c.321-1.496-.541-2.081-1.527-1.714l-21.29 8.151c-1.453.564-1.431 1.374-.247 1.741l5.443 1.693 12.643-7.911c.595-.394 1.136-.176.691.218z" /></svg>
    )

}

function Telegram({ className }) {
    return (
        <svg className={className} id="regular" enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m.415 11.196 5.869 2.925c.227.112.495.104.712-.023l5.224-3.037-3.162 2.802c-.161.143-.253.347-.253.562v6.825c0 .72.919 1.023 1.35.451l2.537-3.373 6.274 3.573c.44.253 1.004-.001 1.106-.504l3.913-19.5c.117-.586-.466-1.064-1.008-.846l-22.5 8.775c-.604.236-.643 1.081-.062 1.37zm21.83-8.249-3.439 17.137-5.945-3.386c-.324-.185-.741-.103-.971.201l-1.585 2.107v-4.244l8.551-7.576c.677-.599-.101-1.664-.874-1.21l-11.39 6.622-3.992-1.989z" /></svg>
    )

}

function TikTok({ className }) {
    return (
        <svg className={className}  enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m480.32 128.39c-29.22 0-56.18-9.68-77.83-26.01-24.83-18.72-42.67-46.18-48.97-77.83-1.56-7.82-2.4-15.89-2.48-24.16h-83.47v228.08l-.1 124.93c0 33.4-21.75 61.72-51.9 71.68-8.75 2.89-18.2 4.26-28.04 3.72-12.56-.69-24.33-4.48-34.56-10.6-21.77-13.02-36.53-36.64-36.93-63.66-.63-42.23 33.51-76.66 75.71-76.66 8.33 0 16.33 1.36 23.82 3.83v-62.34-22.41c-7.9-1.17-15.94-1.78-24.07-1.78-46.19 0-89.39 19.2-120.27 53.79-23.34 26.14-37.34 59.49-39.5 94.46-2.83 45.94 13.98 89.61 46.58 121.83 4.79 4.73 9.82 9.12 15.08 13.17 27.95 21.51 62.12 33.17 98.11 33.17 8.13 0 16.17-.6 24.07-1.77 33.62-4.98 64.64-20.37 89.12-44.57 30.08-29.73 46.7-69.2 46.88-111.21l-.43-186.56c14.35 11.07 30.04 20.23 46.88 27.34 26.19 11.05 53.96 16.65 82.54 16.64v-60.61-22.49c.02.02-.22.02-.24.02z" /></g></svg>
    )

}

function Tumblr({ className }) {
    return (
        <svg className={className} enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m19 22.594-1.175-3.425c-.458.214-1.327.399-1.968.419h-.091c-1.863 0-2.228-1.37-2.244-2.371v-7.47h4.901v-3.633h-4.883v-6.114h-3.575c-.059 0-.162.051-.176.179-.202 1.873-1.098 5.156-4.789 6.469v3.099h2.456v7.842c0 2.655 1.97 6.411 7.148 6.411l-.011-.002h.181c1.786-.03 3.783-.768 4.226-1.404z" /></svg>
    )

}

function Twitter({ className }) {
    return (
        <svg className={className} height="681pt" viewBox="-21 -81 681.33464 681" width="681pt" xmlns="http://www.w3.org/2000/svg"><path d="m200.964844 515.292969c241.050781 0 372.871094-199.703125 372.871094-372.871094 0-5.671875-.117188-11.320313-.371094-16.9375 25.585937-18.5 47.824218-41.585937 65.371094-67.863281-23.480469 10.441406-48.753907 17.460937-75.257813 20.636718 27.054687-16.230468 47.828125-41.894531 57.625-72.488281-25.320313 15.011719-53.363281 25.917969-83.214844 31.808594-23.914062-25.472656-57.964843-41.402344-95.664062-41.402344-72.367188 0-131.058594 58.6875-131.058594 131.03125 0 10.289063 1.152344 20.289063 3.398437 29.882813-108.917968-5.480469-205.503906-57.625-270.132812-136.921875-11.25 19.363281-17.742188 41.863281-17.742188 65.871093 0 45.460938 23.136719 85.605469 58.316407 109.082032-21.5-.660156-41.695313-6.5625-59.351563-16.386719-.019531.550781-.019531 1.085937-.019531 1.671875 0 63.46875 45.171875 116.460938 105.144531 128.46875-11.015625 2.996094-22.605468 4.609375-34.558594 4.609375-8.429687 0-16.648437-.828125-24.632812-2.363281 16.683594 52.070312 65.066406 89.960937 122.425781 91.023437-44.855469 35.15625-101.359375 56.097657-162.769531 56.097657-10.5625 0-21.003906-.605469-31.2617188-1.816407 57.9999998 37.175781 126.8710938 58.871094 200.8867188 58.871094" /></svg>
    )


}

function Viver({ className }) {
    return (
        <svg className={className} height="512pt" viewBox="-21 0 511 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
            <path d="m251.90625 289.269531c-5.855469 5.859375-15.351562 5.859375-21.210938 0l-68.164062-68.164062c-2.8125-2.8125-4.394531-6.628907-4.394531-10.605469 0-3.980469 1.582031-7.792969 4.394531-10.609375l12.117188-12.113281-35.589844-35.589844-29.15625 29.15625c-9.8125 9.8125-9.808594 25.777344 0 35.589844l124.964844 124.972656c4.722656 4.722656 11.042968 7.320312 17.796874 7.320312 6.753907 0 13.074219-2.601562 17.796876-7.324218l29.15625-29.152344-35.589844-35.59375zm0 0" />
            <path d="m468.984375 180.710938c0-48.089844-18.84375-93.417969-53.070313-127.640626-34.222656-34.222656-79.550781-53.070312-127.636718-53.070312h-107.074219s0 0-.003906 0c-48.082031 0-93.410157 18.847656-127.628907 53.066406-34.222656 34.222656-53.070312 79.554688-53.070312 127.640625v84.164063c0 37.238281 11.359375 73.0625 32.851562 103.597656 18.960938 26.9375 44.460938 47.976562 74.191407 61.320312v48.34375c0 24.996094 13.996093 33.863282 25.980469 33.867188 8.5625 0 17.128906-4.226562 25.464843-12.558594l53.855469-53.863281h75.4375c48.085938 0 93.414062-18.847656 127.636719-53.070313 34.222656-34.222656 53.070312-79.550781 53.070312-127.636718zm-137.546875 142.644531-39.765625 39.761719c-10.390625 10.390624-24.242187 16.105468-39.007813 16.105468-14.765624 0-28.617187-5.71875-39.007812-16.105468l-124.96875-124.972657c-21.503906-21.511719-21.503906-56.507812 0-78.015625l39.765625-39.761718c2.8125-2.816407 6.625-4.394532 10.605469-4.394532 3.976562 0 7.792968 1.578125 10.605468 4.394532l56.804688 56.804687c2.8125 2.8125 4.390625 6.628906 4.390625 10.605469 0 3.976562-1.578125 7.792968-4.394531 10.605468l-12.113282 12.113282 46.953126 46.953125 12.117187-12.117188c5.859375-5.855469 15.355469-5.855469 21.210937.003907l56.804688 56.804687c2.816406 2.8125 4.394531 6.628906 4.394531 10.605469 0 3.980468-1.578125 7.796875-4.394531 10.609375zm-108.054688-177.734375c0-8.28125 6.714844-15 15-15 51.972657 0 94.257813 42.28125 94.257813 94.25 0 8.285156-6.714844 15-15 15-8.28125 0-15-6.714844-15-15 0-35.425782-28.824219-64.25-64.257813-64.25-8.285156 0-15-6.714844-15-15zm158.519532 94.257812c-8.285156 0-15-6.71875-15-15 .003906-34.332031-13.367188-66.605468-37.640625-90.882812-24.277344-24.273438-56.550781-37.644532-90.882813-37.644532-8.28125 0-15-6.714843-15-15 0-8.28125 6.71875-15 15-15 42.34375 0 82.152344 16.492188 112.09375 46.433594 29.945313 29.941406 46.433594 69.75 46.429688 112.09375 0 8.28125-6.714844 15-15 15zm0 0" />
        </svg>
    )

}

function Vk({ className }) {
    return (
        <svg className={className} enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m19.915 13.028c-.388-.49-.277-.708 0-1.146.005-.005 3.208-4.431 3.538-5.932l.002-.001c.164-.547 0-.949-.793-.949h-2.624c-.668 0-.976.345-1.141.731 0 0-1.336 3.198-3.226 5.271-.61.599-.892.791-1.225.791-.164 0-.419-.192-.419-.739v-5.105c0-.656-.187-.949-.74-.949h-4.126c-.419 0-.668.306-.668.591 0 .622.945.765 1.043 2.515v3.797c0 .832-.151.985-.486.985-.892 0-3.057-3.211-4.34-6.886-.259-.713-.512-1.001-1.185-1.001h-2.625c-.749 0-.9.345-.9.731 0 .682.892 4.073 4.148 8.553 2.17 3.058 5.226 4.715 8.006 4.715 1.671 0 1.875-.368 1.875-1.001 0-2.922-.151-3.198.686-3.198.388 0 1.056.192 2.616 1.667 1.783 1.749 2.076 2.532 3.074 2.532h2.624c.748 0 1.127-.368.909-1.094-.499-1.527-3.871-4.668-4.023-4.878z" /></svg>
    )

}

function Wechat({ className }) {
    return (
        <svg className={className} enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12.82 9.618c-7.242 3.732-2.425 13.745 6.61 11.131.842.327 1.592.857 2.408 1.251-.211-.71-.436-1.412-.676-2.111 2.8-1.995 3.414-4.818 2.379-7.138-1.616-3.677-6.776-5.183-10.721-3.133zm2.53 3.658c-.209.655-1.156.851-1.615.353-.506-.461-.309-1.424.355-1.63.734-.31 1.582.541 1.26 1.277zm4.78.094h.014c-.257.587-1.141.725-1.575.27-.209-.192-.27-.481-.344-.733.104-.46.42-.937.93-.959.705-.098 1.336.776.975 1.422z" /><path d="m17.414 8.248c-.436-2.144-1.936-3.955-3.824-5h.027v-.001c-6.7-3.786-15.042 1.495-13.43 7.723.433 1.848 1.711 3.397 3.262 4.431-.3.853-.585 1.706-.855 2.565.975-.512 1.95-1.047 2.926-1.567 1.17.377 2.415.561 3.66.52-1.538-4.412 2.407-9.086 8.234-8.671zm-6.077-2.561c.785-.316 1.713.345 1.651 1.19l.002.012c.008.965-1.275 1.567-1.995.913-.747-.538-.535-1.845.342-2.115zm-4.405 1.447c-.172.838-1.291 1.243-1.946.68-.759-.537-.546-1.868.345-2.139.873-.338 1.865.552 1.601 1.459z" /></svg>
    )

}


function Youtube({ className }) {
    return (
        <svg className={className} version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512 512" style={{"enableBackground":"new 0 0 512 512"}} xmlSpace="preserve">
            <g>
                <g>
                    <path d="M490.24,113.92c-13.888-24.704-28.96-29.248-59.648-30.976C399.936,80.864,322.848,80,256.064,80
               c-66.912,0-144.032,0.864-174.656,2.912c-30.624,1.76-45.728,6.272-59.744,31.008C7.36,138.592,0,181.088,0,255.904
               C0,255.968,0,256,0,256c0,0.064,0,0.096,0,0.096v0.064c0,74.496,7.36,117.312,21.664,141.728
               c14.016,24.704,29.088,29.184,59.712,31.264C112.032,430.944,189.152,432,256.064,432c66.784,0,143.872-1.056,174.56-2.816
               c30.688-2.08,45.76-6.56,59.648-31.264C504.704,373.504,512,330.688,512,256.192c0,0,0-0.096,0-0.16c0,0,0-0.064,0-0.096
               C512,181.088,504.704,138.592,490.24,113.92z M192,352V160l160,96L192,352z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )

}

export { Badoo, BaiduLogo, BigSkypeLogo, Facebook, FourSquare, GooglePlus, Instagram, Line, Linkedin, NightClub, Qq, QzoneLogo, Reddit, Renren, SinaWeibo, SnapChat, Tagged, Telegram1, Telegram, TikTok, Tumblr, Twitter, Viver, Vk, Wechat, Youtube }