import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Location } from '../assets/svg/icon';
import axios from 'axios';
import qs from 'qs';
import {APIURL} from '../../matcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faHome, faFax} from '@fortawesome/free-solid-svg-icons';
class Contact extends Component {
    constructor(props) 
    {
        super(props)
        this.state = {
          name : '',
          phone : '',
          subject : '',
          email : '',
          message : ''
        };
    }

    handleInputChange = (event) => 
    {
      const { value, name } = event.target;
      this.setState({
        [name]: value
      });
    }

    onSubmit = (event) => 
    {
      event.preventDefault();
      this.btn.setAttribute("disabled", "disabled");
      axios({
          method: 'get',
          url: APIURL+'contact-us?name='+this.state.name+'&phone='+this.state.phone+'&email='+this.state.email+'&subject='+this.state.subject+'&message='+this.state.message,
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        }).then((res) => 
        {
            alert(res.data.message)      
            window.location.reload(false);
        })  
    }

    render() 
    {
       return (
        <>
        <div className="industify_fn_pagetitle">
            <div className="container">
                <div className="title_holder">
                    <h3>Get in Touch</h3>
                    <div className="industify_fn_breadcrumbs">
                        <ul>
                            <li><Link to="/"><a title="Home">Home</a></Link></li>
                            <li className="separator"><span></span></li>
                            <li><span className="bread-current">Contact Us</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* /Page Title */}

        {/* Contact */}
        <div className="industify_fn_contact">
            <div className="container">
                <div className="contact_in">
                    <div className="map_holder">
                        <iframe width="100%" height="400" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3183.4206230842146!2d77.46240897116523!3d28.535908763333108!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce9cc86679e41%3A0xdb1c80d39ad41445!2sToy%20City%20Complex!5e0!3m2!1sen!2sin!4v1652265773460!5m2!1sen!2sin"></iframe>
                    </div>
                    <div className="contact_holder">
                        <div className="contact_left">
                            <h3>Get In touch with Us</h3>
                            <form className="contact_form" onSubmit={this.onSubmit} method="get" autoComplete="off">
                                {/*  */}
                                <div className="items">
                                    <div className="item">
                                        <input id="name" value={this.state.name}  onChange={this.handleInputChange} type="text" placeholder="Name" name="name" required/>
                                    </div>
                                    <div className="item">
                                        <input id="phone" type="text" value={this.state.phone}  onChange={this.handleInputChange} minlength="10" maxlength="10" placeholder="Phone Number"  name="phone" required/>
                                    </div>
                                    <div className="item">
                                        <input id="email" type="email" placeholder="Email" value={this.state.email}  onChange={this.handleInputChange} name="email" required/>
                                    </div>
                                    <div className="item">
                                        <input id="subject" type="text" value={this.state.subject}  onChange={this.handleInputChange} placeholder="Subject"  name="subject" required/>
                                    </div>
                                    <div className="item">
                                        <textarea id="message" placeholder="Message"  name="message"  onChange={this.handleInputChange} required>{this.state.message}</textarea>
                                    </div>
                                    <div className="item">
                                        <button type="submit" ref={btn => { this.btn = btn; }}>Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="contact_right">
                            <div className="fn_cs_location_list">
                                <ul className="list">

                                    <li className="location_item">
                                        <div className="item">
                                            <div className="title_holder">
                                                <span className="icon_wrapper">
                                                    <span className="icon">
                                                        <FontAwesomeIcon icon={faHome} />
                                                    </span>
                                                    <span className="shape"></span>
                                                </span>
                                                <h3>Office Address</h3>
                                            </div>
                                            <div className="content_holder">
                                                <ul>
                                                    <li>SF-01, Toy City Complex, 2nd Floor Ecotech-III, Greater Noida, Gautambudh Nagar-201310 (UP) India</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    
                                    <li className="location_item">
                                        <div className="item">
                                            <div className="title_holder">
                                                <span className="icon_wrapper">
                                                    <span className="icon">
                                                        {/*<Location className="fn__svg" />*/}
                                                        <FontAwesomeIcon icon={faPhone} />
                                                    </span>
                                                    <span className="shape"></span>
                                                </span>
                                                <h3>Phone Number</h3>
                                            </div>
                                            <div className="content_holder">
                                                <ul>
                                                    <li>+91-9650263574 , +91-9013976908</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="location_item">
                                        <div className="item">
                                            <div className="title_holder">
                                                <span className="icon_wrapper">
                                                    <span className="icon">
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </span>
                                                    <span className="shape"></span>
                                                </span>
                                                <h3>Email Address</h3>
                                            </div>
                                            <div className="content_holder">
                                                <ul>
                                                    <li>info@rcrispsolutions.com</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="location_item">
                                        <div className="item">
                                            <div className="title_holder">
                                                <span className="icon_wrapper">
                                                    <span className="icon">
                                                        <FontAwesomeIcon icon={faFax} />
                                                    </span>
                                                    <span className="shape"></span>
                                                </span>
                                                <h3>Landline</h3>
                                            </div>
                                            <div className="content_holder">
                                                <ul>
                                                    <li>0120-2960505 , 0120-2973838</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* /Contact */}

        </>
    )
    }
}
export default Contact;
