import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Location } from '../../assets/svg/icon';
import Sidebar from '../sidebar';
import Img from "../../assets/img/blog/1.jpg"
import axios from 'axios';
import qs from 'qs';
import {APIURL} from '../../../matcher';
class Services extends Component {
    constructor(props) 
    {
        super(props);
        this.state = {
          services:[]
        };
    }

    componentDidMount() 
    {  
        axios.get(APIURL+'service-list')
        .then((res) => 
        {
            if(res.data.list.length > 0)
            {
              this.setState({
                services: res.data.list,
                loading: false,
              });
            }
            else
            {
              this.setState({
                services: '',
                loading: false,
              });
            }
        })
    }

    render() 
    {
        const { services } = this.state;
        console.log(services)
            return (
                <>
                {/* Page Title */}
                <div className="industify_fn_pagetitle">
                    <div className="container">
                        <div className="title_holder">
                            <h3>Our Services</h3>
                            <div className="industify_fn_breadcrumbs">
                                <ul>
                                    <li><Link href="/"><a title="Home">Home</a></Link></li>
                                    <li className="separator"><span></span></li>
                                    <li><span className="bread-current"> Our Services</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /Page Title */}
                {/* Sidebar Page */}
                <div className="industify_fn_sidebarpage">
                    <div className="container">
                        <div className="s_inner">
                            {/* Main Sidebar: Left */}
                            <div className="industify_fn_leftsidebar">
                                <ul className="industify_fn_service_list">
                                    {
                                    services.map((service) =>
                                    {
                                      return(
                                        <li>
                                            <div className="item">
                                                <div className="item_in">
                                                    <div className="img_holder">
                                                        <div className="img_abs" style={{ "backgroundImage": `url(${service.image})` }}></div>
                                                        <Link to={'services/'+service.url}><a></a></Link>
                                                    </div>
                                                    <div className="title">
                                                        <h3><Link to={'services/'+service.url}><a>{service.name}</a></Link></h3>
                                                        <p dangerouslySetInnerHTML={{__html:service.short}}></p>
                                                    </div>
                                                    <div className="read_more">
                                                        <Link to={'services/'+service.url}><a>Read More</a></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        )
                                    })
                                    }
                                </ul>
                                <div className="clearfix"></div>
                            </div>
                            {/* /Main Sidebar: Left */}
                            {/* Main Sidebar: Right */}
                            <div className="industify_fn_rightsidebar">
                                {/* Service List */}
                                <div className="service_list_as_function">
                                    <div className="title">
                                        <h3>Full list of Services</h3>
                                    </div>
                                    <div className="list_holder">
                                        <ul>
                                        {
                                            services.map((service) =>
                                            {
                                              return(
                                                    <li><Link to={'services/'+service.url}><a>{service.name}</a></Link></li>
                                                )
                                          })
                                        }
                                        </ul>
                                    </div>
                                </div>
                                {/* /Service List */}
                                {/* Get Sidebar */}
                                <Sidebar />
                                {/* /Get Sidebar */}
                            </div>
                            {/* Main Sidebar: Right */}
                        </div>
                    </div>
                </div>
                {/* /Sidebar Page */}
                </>
            )
    }
}
export default Services;
